import { APICall } from '../core';
import { isString, isObject } from '../../helpers/utils';
import User from './User';
import Location from './Location';
import Preferences from './Preferences';
import { UPLOAD_DIR } from '../../helpers/constants';

/**
 * This class exposes a set of methods for managing users and user locations.
 * 
 * @class
 */
export default class UsersService {

    constructor(bc) {
        this.bc = bc;
    }

    /**
     * Gets all users. Requires admin privileges.
     * 
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return an Array with users
     * or an Error with the problem.
     */
    getAll() {
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/users`,
            'GET',
        );
        return request.send();
    }

    /**
     * Gets data of the logged in user.
     * 
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return an Object 
     * with user's data or an Error with the problem.
     */
    getLoggedInUserData() {
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/users/me`,
            'GET',
        );
        return request.send();
    }

    /**
     * Gets data of the user specified by id.
     * 
     * @param {String} id User's id
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return an Object 
     * with user's data or an Error with the problem.
     */
    getUserById(id) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'User id was not specified properly.'
            });
        }
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/users/${id}`,
            'GET',
        );
        return request.send();
    }

    /**
     * Updates data of the user specified by id.
     * 
     * @param {String} id User's id
     * @param {Object} u Instance of the User class
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return OK status 
     * or an Error with the problem.
     */
    updateUserData(id, u) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'User id was not specified properly.'
            });
        }
        if (!(u instanceof User)) {
            return Promise.reject({
                status: 0,
                message: 'User data argument must be an instance of the User class.'
            });
        }
        if (!u.validate()) {
            return Promise.reject({
                status: 0,
                message: 'Invalid user data.',
                errors: u.getValidationErrors()
            });
        }
        let payload = u.loadToJSON();
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/users/${id}`,
            'PUT',
            payload
        );
        return request.send();
    }

    /**
     * Updates the role of the user specified by id.
     * 
     * @param {String} id User's id
     * @param {String} role New user role
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return OK status 
     * or an Error with the problem.
     */
    changeUserRole(id, role) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'User id was not specified properly.'
            });
        }
        if (!isString(role) || role === '') {
            return Promise.reject({
                status: 0,
                message: 'User role was not specified properly.'
            });
        }
        let payload = { id: id, role: role };
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/users/role/${id}`,
            'POST',
            payload
        );
        return request.send();
    }

    /**
     * Gets locations of the user specified by id.
     * 
     * @param {String} id User's id
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return an Array with user's
     * locations or an Error with the problem.
     */
    getUserLocations(id) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'User id was not specified properly.'
            });
        }
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/users/${id}/locations`,
            'GET',
        );
        return request.send();
    }

    /**
     * Adds new location for the user specified by id.
     * 
     * @param {String} id User's id 
     * @param {Object} l Instance of the Location class
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return OK status 
     * or an Error with the problem.
     */
    addUserLocation(id, l) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'User id was not specified properly.'
            });
        }
        if (!(l instanceof Location)) {
            return Promise.reject({
                status: 0,
                message: 'Location data argument must be an instance of the Location class.'
            });
        }
        if (!l.validate()) {
            return Promise.reject({
                status: 0,
                message: 'Invalid location data.',
                errors: l.getValidationErrors()
            });
        }
        let payload = l.loadToJSON();
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/users/${id}/locations`,
            'POST',
            payload
        );
        return request.send();
    }

    /**
     * Updates user's location.
     * 
     * @param {String} id User's id 
     * @param {String} lid Location's id
     * @param {Object} data Instance of the Location class
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return OK status 
     * or an Error with the problem.
     */
    updateUserLocation(id, lid, l) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'User id was not specified properly.'
            });
        }
        if (!isString(lid) || lid === '') {
            return Promise.reject({
                status: 0,
                message: 'Location id was not specified properly.'
            });
        }
        if (!(l instanceof Location)) {
            return Promise.reject({
                status: 0,
                message: 'Location data argument must be an instance of the Location class.'
            });
        }
        if (!l.validate()) {
            return Promise.reject({
                status: 0,
                message: 'Invalid location data.',
                errors: l.getValidationErrors()
            });
        }
        let payload = l.loadToJSON();
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/users/${id}/locations/${lid}`,
            'PUT',
            payload
        );
        return request.send();
    }

    /**
     * Deletes user's location.
     * 
     * @param {String} id User's id
     * @param {String} lid Location's id
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return OK status 
     * or an Error with the problem.
     */
    deleteUserLocation(id, lid) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'User id is was not specified properly.'
            });
        }
        if (!isString(lid) || lid === '') {
            return Promise.reject({
                status: 0,
                message: 'Location id was not specified properly.'
            });
        }
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/users/${id}/locations/${lid}`,
            'DELETE',
        );
        return request.send();
    }

    /**
     * Gets all profiles associated with the user specified by id.
     * 
     * @param {String} id User's id
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return an Array with user's
     * profiles or an Error with the problem.
     */
    getUserProfiles(id) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'User id was not specified properly.'
            });
        }
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/users/${id}/profiles`,
            'GET',
        );
        return request.send();
    }

    uploadImage(img) {
        if (!(img instanceof File)) {
            return Promise.reject({
                status: 0,
                message: 'Image should be a File object instance.'
            });
        }
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/upload`,
            'POST',
            {
                file: img,
                dir: UPLOAD_DIR.AVATAR
            }
        );
        return request.upload();
    }

    getUserPreferences(id) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'User id was not specified properly.'
            });
        }
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/users/${id}/preferences`,
            'GET',
        );
        return request.send();
    }

    updateUserPreferences(id, p) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'User id was not specified properly.'
            });
        }
        if (!(p instanceof Preferences)) {
            return Promise.reject({
                status: 0,
                message: 'Preferences data argument must be an instance of the Preferences class.'
            });
        }
        if (!p.validate()) {
            return Promise.reject({
                status: 0,
                message: 'Invalid preferences data.',
                errors: p.getValidationErrors()
            });
        }
        let payload = p.loadToJSON();
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/users/${id}/preferences`,
            'PATCH',
            payload
        );
        return request.send();
    }

    /**
     * Changes user's password.
     * 
     * @param {String} id User id
     * @param {Object} data Object containing required properties for password changing
     * @property {String} data.old_password User's old password
     * @property {String} data.new_password New password
     * @property {String} data.confirm_password New password confirmation
     * @return {Promise} Returns a Promise that, when fulfilled, will either return an Array with user's
     * profiles or an Error with the problem.
     */
    changePassword(id, data) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'User id was not specified properly.'
            });
        }
        if (
            !data ||
            !isObject(data) ||
            !data.hasOwnProperty('old_password') ||
            !isString(data.old_password) ||
            data.old_password === '' ||
            !data.hasOwnProperty('new_password') ||
            !isString(data.new_password) ||
            data.new_password === '' ||
            !data.hasOwnProperty('confirm_password') ||
            !isString(data.confirm_password) ||
            data.confirm_password === ''
        ) {
            return Promise.reject({
                status: 0,
                message: 'Invalid type or missing properties in data parameter.'
            });
        }
        if (data.new_password !== data.confirm_password) {
            return Promise.reject({
                status: 0,
                message: 'Confirm password mismatch.'
            });
        }
        let payload = {
            old_password: data.old_password,
            new_password: data.new_password,
            confirm_password: data.confirm_password
        };
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/users/${id}/change-password`,
            'PATCH',
            payload
        );
        return request.send();
    }
}