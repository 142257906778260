import {
    isObject,
    extractJsonProps,
    objectKeysToSnakeCase
} from '../../helpers/utils';
import { Validator } from '../core';
const ACCOUNT_REQUEST_SCHEMA = {
    id: { type: 'string' },
    first_name: { type: 'string', rules: { required: true, minLength: 2 } },
    last_name: { type: 'string', rules: { required: true, minLength: 2 } },
    email: { type: 'string', rules: { required: true } },
    continent: { type: 'string' },
    country: { type: 'string' },
    zipcode: { type: 'string' },
    city: { type: 'string' },
    longitude: { type: 'number' },
    latitude: { type: 'number' },
    role: { type: 'string' },
    status: { type: 'string' }
};

export default class AccountRequest {
    constructor() {
        this.validator = new Validator();
        // JSON properties
        this._id = null;
        this._firstName = null;
        this._lastName = null;
        this._email = null;
        this._continent = null;
        this._country = null;
        this._zipcode = null;
        this._city = null;
        this._longitude = null;
        this._latitude = null;
        this._role = null;
        this._status = null;
    }

    loadToJSON() {
        // get all properties, ignore methods
        var data = JSON.parse(JSON.stringify(this));
        // extract properties that are part of the json
        extractJsonProps(data);
        return objectKeysToSnakeCase(data);
    }

    loadFromJSON(data) {
        if (!isObject(data)) {
            throw new Error('Account Request data was not specified properly.');
        }
        // Use json property key as setter method for class property
        for (let i in data) {
            this[i] = data[i];
        }
    }

    /* SETTER METHODS */

    set id(id) {
        this._id = id;
    }

    set first_name(firstName) {
        this._firstName = firstName;
    }

    set last_name(lastName) {
        this._lastName = lastName;
    }

    set email(email) {
        this._email = email;
    }

    set continent(continent) {
        this._continent = continent;
    }

    set country(country) {
        this._country = country;
    }

    set zipcode(zipcode) {
        this._zipcode = zipcode;
    }

    set city(city) {
        this._city = city;
    }

    set longitude(longitude) {
        this._longitude = longitude;
    }

    set latitude(latitude) {
        this._latitude = latitude;
    }

    set role(role) {
        this._role = role;
    }

    set status(status) {
        this._status = status;
    }

    /* GETTER METHODS */

    get id() {
        return this._id;
    }

    get first_name() {
        return this._firstName;
    }

    get last_name() {
        return this._lastName;
    }

    get email() {
        return this._email;
    }

    get continent() {
        return this._continent;
    }

    get country() {
        return this._country;
    }

    get zipcode() {
        return this._zipcode;
    }

    get city() {
        return this._city;
    }

    get longitude() {
        return this._longitude;
    }

    get latitude() {
        return this._latitude;
    }

    get role() {
        return this._role;
    }

    get status() {
        return this._status;
    }

    validate() {
        if (this.validator.validate(ACCOUNT_REQUEST_SCHEMA, this)) {
            return true;
        }
        return false;
    }

    getValidationErrors() {
        return this.validator.getValidationErrors();
    }
}