import { APICall } from '../core';
import { isString, isNumber } from '../../helpers/utils';

export default class StatsService {

    constructor(bc) {
        this.bc = bc;
    }

    /**
     * 
     * @param {string} id User id
     * @param {string} type Statistic type (year|month)
     * @param {number} month 
     * @param {number} year 
     */
    userOrders(id, type, month, year) {
        let queryParams = [];
        let queryString = '';
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'User id was not specified properly.'
            });
        }
        if (isString(type) & type !== '') {
            queryParams.push(`type=${type}`);
        }
        if (isNumber(month)) {
            queryParams.push(`month=${month}`);
        }
        if (isNumber(year)) {
            queryParams.push(`year=${year}`);
        }
        if (queryParams.length > 0) {
            queryString += `?${queryParams.join('&')}`;
        }
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/stats/user/${id}/orders${queryString}`,
            'GET'
        );
        return request.send();
    }

    /**
     * 
     * @param {string} type Statistic type (year|month|day)
     * @param {number} year 
     * @param {number} month 
     * @param {number} day 
     */
    dashboardOrdersCount(type, year, month, day) {
        let queryParams = [];
        let queryString = '';
        if (isString(type) & type !== '') {
            queryParams.push(`type=${type}`);
        }
        if (isNumber(year)) {
            queryParams.push(`year=${year}`);
        }
        if (isNumber(month)) {
            queryParams.push(`month=${month}`);
        }
        if (isNumber(day)) {
            queryParams.push(`day=${day}`);
        }
        if (queryParams.length > 0) {
            queryString += `?${queryParams.join('&')}`;
        }
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/stats/dashboard/orders/count${queryString}`,
            'GET'
        );
        return request.send();
    }

    /**
     * 
     * @param {string} type Statistic type (year|month|day)
     * @param {number} year 
     * @param {number} month 
     * @param {number} day 
     */
    dashboardOrdersAvgPrice(type, year, month, day) {
        let queryParams = [];
        let queryString = '';
        if (isString(type) & type !== '') {
            queryParams.push(`type=${type}`);
        }
        if (isNumber(year)) {
            queryParams.push(`year=${year}`);
        }
        if (isNumber(month)) {
            queryParams.push(`month=${month}`);
        }
        if (isNumber(day)) {
            queryParams.push(`day=${day}`);
        }
        if (queryParams.length > 0) {
            queryString += `?${queryParams.join('&')}`;
        }
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/stats/dashboard/orders/avg-price${queryString}`,
            'GET'
        );
        return request.send();
    }

    /**
     * 
     * @param {string} type Statistic type (year|month|day)
     * @param {number} year 
     * @param {number} month 
     * @param {number} day 
     * @param {number} limit Number of results to be returned
     */
    dashboardTopSellingProducts(type, year, month, day, limit) {
        let queryParams = [];
        let queryString = '';
        if (isString(type) & type !== '') {
            queryParams.push(`type=${type}`);
        }
        if (isNumber(year)) {
            queryParams.push(`year=${year}`);
        }
        if (isNumber(month)) {
            queryParams.push(`month=${month}`);
        }
        if (isNumber(day)) {
            queryParams.push(`day=${day}`);
        }
        if (isNumber(limit)) {
            queryParams.push(`limit=${limit}`);
        }
        if (queryParams.length > 0) {
            queryString += `?${queryParams.join('&')}`;
        }
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/stats/dashboard/products/top-selling${queryString}`,
            'GET'
        );
        return request.send();
    }

    dashboardProductsCounters() {
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/stats/dashboard/products/counters`,
            'GET'
        );
        return request.send();
    }
}