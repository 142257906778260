import { APICall } from '../core';
import { isString } from '../../helpers/utils';
import Company from './Company';

export default class CompaniesService {

    constructor(bc) {
        this.bc = bc;
    }

    /**
     * Gets all companies.
     * 
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return an Array with the
     * companies or an Error with the problem.
     */
    getAll() {
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/companies`,
            'GET',
        );
        return request.send();
    }

    /**
     * Gets company with specified id.
     * 
     * @param {String} id Company id
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return the
     * company data or an Error with the problem.
     */
    getCompanyById(id) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Company id was not specified properly'
            });
        }
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/companies/${id}`,
            'GET',
        );
        return request.send();
    }

    /**
     * Inserts new company.
     * 
     * @param {Object} c Instance of the Company class
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return an Object with the
     * company data or an Error with the problem.
     */
    insertCompany(c) {
        if (!(c instanceof Company)) {
            return Promise.reject({
                status: 0,
                message: 'Company data argument must be an instance of the Company class'
            });
        }
        if (!c.validate()) {
            return Promise.reject({
                status: 0,
                message: 'Invalid company data',
                errors: c.getValidationErrors()
            });
        }
        let payload = c.loadToJSON();
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/companies`,
            'POST',
            payload
        );
        return request.send();
    }

    /**
     * Updates company with specified id.
     * 
     * @param {String} id Company id 
     * @param {Object} c Instance of the Company class
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return success status
     * or an Error with the problem.
     */
    updateCompany(id, c) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Company id was not specified properly'
            });
        }
        if (!(c instanceof Company)) {
            return Promise.reject({
                status: 0,
                message: 'Company data argument must be an instance of the Company class'
            });
        }
        if (!c.validate()) {
            return Promise.reject({
                status: 0,
                message: 'Invalid company data',
                errors: c.getValidationErrors()
            });
        }
        let payload = c.loadToJSON();
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/companies/${id}`,
            'PUT',
            payload
        );
        return request.send();
    }

    /**
     * Deletes company with specified id.
     * 
     * @param {String} id Company id
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return success status
     * or an Error with the problem.
     */
    deleteCompany(id) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Company id was not specified properly.'
            });
        }
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/companies/${id}`,
            'DELETE'
        );
        return request.send();
    }
}