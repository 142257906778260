import { APICall } from '../core';
import { isString } from '../../helpers/utils';
import Bookmark from './Bookmark';

export default class BookmarksService {

    constructor(bc) {
        this.bc = bc;
    }

    getCategoryBookmarks(uid, type) {
        if (!uid || !isString(uid) || uid === '') {
            return Promise.reject({
                status: 0,
                message: 'User id was not specified properly.'
            });
        }
        if (!type || !isString(type) || type === '') {
            return Promise.reject({
                status: 0,
                message: 'Bookmark type was not specified properly.'
            });
        }
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/bookmarks/${uid}/${type}`,
            'GET'
        );
        return request.send();
    }

    createBookmark(uid, type, b) {
        if (!uid || !isString(uid) || uid === '') {
            return Promise.reject({
                status: 0,
                message: 'User id was not specified properly.'
            });
        }
        if (!type || !isString(type) || type === '') {
            return Promise.reject({
                status: 0,
                message: 'Bookmark type was not specified properly.'
            });
        }
        if (!(b instanceof Bookmark)) {
            return Promise.reject({
                status: 0,
                message: 'Bookmark data argument must be an instance of the Bookmark class.'
            });
        }
        if (!b.validate()) {
            return Promise.reject({
                status: 0,
                message: 'Invalid bookmark data.',
                errors: b.getValidationErrors()
            });
        }
        let payload = b.loadToJSON();
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/bookmarks/${uid}/${type}`,
            'POST',
            payload
        );
        return request.send();
    }

    removeBookmark(uid, type, id) {
        if (!uid || !isString(uid) || uid === '') {
            return Promise.reject({
                status: 0,
                message: 'User id was not specified properly.'
            });
        }
        if (!type || !isString(type) || type === '') {
            return Promise.reject({
                status: 0,
                message: 'Bookmark type was not specified properly.'
            });
        }
        if (!id || !isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Bookmark id was not specified properly.'
            });
        }
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/bookmarks/${uid}/${type}/${id}`,
            'DELETE'
        );
        return request.send();
    }
}