import {
    isArray,
    isObject,
    extractJsonProps,
    objectKeysToSnakeCase
} from '../../helpers/utils';
import { Validator } from '../core';

const PRODUCT_SCHEMA = {
    id: { type: 'string' },
    name: { type: 'string', rules: { required: true, minLength: 2 } },
    description: { type: 'string', rules: { required: true, minLength: 2 } },
    detail_description: { type: 'string' },
    images: { type: 'array', rules: { itemsType: 'classInstance' } },
    tags: { type: 'array', rules: { itemsType: 'string' } },
    category: { type: 'array', rules: { itemsType: 'classInstance' } },
    badges: { type: 'array', rules: { itemsType: 'string' } },
    barcode: { type: 'string' },
    ipid: { type: 'string' },
    vendor_id: { type: 'string', rules: { required: true } },
    manufacturer_id: { type: 'string', rules: { required: true } },
    country_of_origin: { type: 'string' },
    quantity: { type: 'integer', rules: { required: true, minimum: 0 } },
    available_from: { type: 'date', rules: { required: true } },
    available_to: { type: 'date' },
    type: { type: 'string', rules: { required: true } },
    active: { type: 'boolean' },
    buy_max_limit: { type: 'integer' },
    warehouse_position: { type: 'string' },
    meta: { type: 'classInstance' }
};

const RECORD_SCHEMA = {
    id: { type: 'string', rules: { required: true } },
    name: { type: 'string', rules: { required: true } }
};

const IMAGE_SCHEMA = {
    path: { type: 'string', rules: { required: true } },
    cover: { type: 'boolean', rules: { required: true } }
}

export default class Product {

    constructor() {
        this.validator = new Validator();
        // JSON properties
        this._availableFrom = null;
        this._availableTo = null;
        this._barcode = null;
        this._category = null;
        this._countryOfOrigin = null;
        this._description = null;
        this._detailDescription = null;
        this._id = null;
        this._images = null;
        this._ipid = null;
        this._manufacturerId = null;
        this._name = null;
        this._quantity = null;
        this._tags = null;
        this._type = null;
        this._vendorId = null;
        this._badges = null;
        this._active = null;
        this._buyMaxLimit = null;
        this._warehousePosition = null;
    }

    loadToJSON() {
        // get all properties, ignore methods
        var data = JSON.parse(JSON.stringify(this));
        // remove all private properties
        extractJsonProps(data);
        return objectKeysToSnakeCase(data);
    }

    /**
     * 
     * @param {*} data 
     */
    loadFromJSON(data) {
        if (!isObject(data)) {
            throw new Error('Product data was not specified properly.');
        }
        // Use json property key as setter method for class property
        for (let i in data) {
            this[i] = data[i];
        }
    }

    /* SETTER METHODS */

    set id(id) {
        this._id = id;
    }

    set name(name) {
        this._name = name;
    }

    set description(description) {
        this._description = description;
    }

    set detail_description(description) {
        this._detailDescription = description;
    }

    set images(images) {
        if (!isArray(images)) {
            return;
        }
        this._images = [];
        for (let i in images) {
            let img = new Image();
            img.loadFromJSON(images[i]);
            this._images.push(img);
        }
    }

    set tags(tags) {
        this._tags = tags;
    }

    set category(category) {
        if (!isArray(category)) {
            return;
        }
        this._category = [];
        for (let i in category) {
            let c = new Record();
            c.loadFromJSON(category[i]);
            this._category.push(c);
        }
    }

    set badges(badges) {
        this._badges = badges;
    }

    set barcode(barcode) {
        this._barcode = barcode;
    }

    set ipid(ipid) {
        this._ipid = ipid;
    }

    set vendor_id(vendorId) {
        this._vendorId = vendorId;
    }

    set manufacturer_id(manufacturerId) {
        this._manufacturerId = manufacturerId;
    }

    set country_of_origin(origin) {
        this._countryOfOrigin = origin;
    }

    set quantity(quantity) {
        this._quantity = quantity;
    }

    set available_from(dateFrom) {
        this._availableFrom = dateFrom;
    }

    set available_to(dateTo) {
        this._availableTo = dateTo;
    }

    set type(type) {
        this._type = type;
    }

    set active(active) {
        this._active = active;
    }

    set buy_max_limit(buyMaxLimit) {
        this._buyMaxLimit = buyMaxLimit;
    }

    set warehouse_position(warehousePosition) {
        this._warehousePosition = warehousePosition;
    }

    /* GETTER METHODS */

    get id() {
        return this._id;
    }

    get name() {
        return this._name;
    }

    get description() {
        return this._description;
    }

    get detail_description() {
        return this._detailDescription;
    }

    get images() {
        return this._images;
    }

    get tags() {
        return this._tags;
    }

    get category() {
        return this._category;
    }

    get badges() {
        return this._badges;
    }

    get barcode() {
        return this._barcode;
    }

    get ipid() {
        return this._ipid;
    }

    get vendor_id() {
        return this._vendorId;
    }

    get manufacturer_id() {
        return this._manufacturerId;
    }

    get country_of_origin() {
        return this._countryOfOrigin;
    }

    get quantity() {
        return this._quantity;
    }

    get available_from() {
        return this._availableFrom;
    }

    get available_to() {
        return this._availableTo;
    }

    get type() {
        return this._type;
    }

    get active() {
        return this._active;
    }

    get buy_max_limit() {
        return this._buyMaxLimit;
    }

    get warehouse_position() {
        return this._warehousePosition;
    }

    validate() {
        if (this.validator.validate(PRODUCT_SCHEMA, this)) {
            return true;
        }
        return false;
    }

    getValidationErrors() {
        return this.validator.getValidationErrors();
    }
}

/**
 * Represents a product record containing id and name property.
 * 
 * @class
 * @classdesc Used for defining product category
 */
class Record {
    constructor() {
        this.validator = new Validator();
        // JSON properties
        this._id = null;
        this._name = null;
    }

    loadFromJSON(data) {
        if (!isObject(data)) {
            throw new Error('Record data was not specified properly.');
        }
        // Use json property key as setter method for class property
        for (let i in data) {
            this[i] = data[i];
        }
    }

    /* SETTER METHODS */

    set id(id) {
        this._id = id;
    }

    set name(name) {
        this._name = name;
    }

    /* GETTER METHODS */

    get id() {
        return this._id;
    }

    get name() {
        return this._name;
    }

    validate() {
        if (this.validator.validate(RECORD_SCHEMA, this)) {
            return true;
        }
        return false;
    }

    getValidationErrors() {
        return this.validator.getValidationErrors();
    }
}

/**
 * Represents a product image.
 * 
 * @class
 */
class Image {
    constructor() {
        this.validator = new Validator();
        // JSON properties
        this._path = null;
        this._cover = null;
    }

    loadFromJSON(data) {
        if (!isObject(data)) {
            throw new Error('Image data was not specified properly.');
        }
        // Use json property key as setter method for class property
        for (let i in data) {
            this[i] = data[i];
        }
    }

    /* SETTER METHODS */

    set path(path) {
        this._path = path;
    }

    set cover(cover) {
        this._cover = cover;
    }

    /* GETTER METHODS */

    get path() {
        return this._path;
    }

    get cover() {
        return this._cover;
    }

    validate() {
        if (this.validator.validate(IMAGE_SCHEMA, this)) {
            return true;
        }
        return false;
    }

    getValidationErrors() {
        return this.validator.getValidationErrors();
    }
}