import { APICall } from '../core';
import { isString } from '../../helpers/utils';
import Receiving from './Receiving';
import { Validator } from '../../classes/core';

export default class WarehouseService {
    constructor(bc) {
        this.bc = bc;
    }

    /**
     * Gets all receivings
     * @returns {promise} Returns a Promise that, when fulfilled, will either return an Array with the
     * receivings or an Error with the problem
     */
    getReceivings() {
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/warehouse/receiving`,
            'GET'
        );
        return request.send();
    }

    /**
     * Gets receiving with specified id
     * @param {string} id Receiving id
     * @returns {promise} Returns a Promise that, when fulfilled, will either return an Object with the
     * receiving data or an Error with the problem
     */
    getReceivingById(id) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Receiving id was not specified properly'
            });
        }
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/warehouse/receiving/${id}`,
            'GET'
        );
        return request.send();
    }

    /**
     * Creates new receiving
     * @param {object} r Instance of the Receiving class
     * @returns {promise} Returns a Promise that, when fulfilled, will either return an Object with the
     * receiving data or an Error with the problem
     */
    insertReceiving(r) {
        if (!(r instanceof Receiving)) {
            return Promise.reject({
                status: 0,
                message: 'Receiving data argument must be an instance of the Receiving class'
            });
        }
        if (!r.validate()) {
            return Promise.reject({
                status: 0,
                message: 'Invalid receiving data',
                errors: r.getValidationErrors()
            });
        }
        let payload = r.loadToJSON();
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/warehouse/receiving`,
            'POST',
            payload
        );
        return request.send();
    }

    /**
     * Deletes receiving
     * @param {string} id Receiving id
     * @returns {promise} Returns a Promise that, when fulfilled, will either return success status
     * or an Error with the problem
     */
    deleteReceiving(id) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Receiving id was not specified properly'
            });
        }
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/warehouse/receiving/${id}`,
            'DELETE'
        );
        return request.send();
    }

    /**
     * Updates receiving status
     * @param {string} id Receiving id
     * @param {string} status Receiving status
     * @returns {promise} Returns a Promise that, when fulfilled, will either return success status
     * or an Error with the problem
     */
    updateReceivingStatus(id, status) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Receiving id was not specified properly'
            });
        }
        if (!isString(status) || status === '') {
            return Promise.reject({
                status: 0,
                message: 'Receiving status was not specified properly'
            });
        }
        let payload = { status: status };
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/warehouse/receiving/${id}/status`,
            'PATCH',
            payload
        );
        return request.send();
    }

    /**
     * Updates receiving product quantity, price and price_with_tax
     * @param {string} rid Receiving id
     * @param {string} pid Receiving product id 
     * @param {object} payload JSON object containing product's quantity, price and price_with_tax
     * @property {integer} payload.quantity Product quantity
     * @property {float} payload.price Product price
     * @property {float} payload.price_with_tax Product price with tax
     * @returns {promise} Returns a Promise that, when fulfilled, will either return success status
     * or an Error with the problem
     */
    updateReceivingProduct(rid, pid, payload) {
        if (!isString(rid) || rid === '') {
            return Promise.reject({
                status: 0,
                message: 'Receiving id was not specified properly'
            });
        }
        if (!isString(pid) || pid === '') {
            return Promise.reject({
                status: 0,
                message: 'Receiving product id was not specified properly'
            });
        }
        let v = new Validator();
        const SCHEMA = {
            quantity: { type: 'integer', rules: { required: true, exclusiveMinimum: 0 } },
            price: { type: 'number', rules: { required: true, exclusiveMinimum: 0 } },
            price_with_tax: { type: 'number', rules: { required: true, exclusiveMinimum: 0 } }
        };
        if (!v.validate(SCHEMA, payload)) {
            return Promise.reject({
                status: 0,
                message: 'Invalid receiving product data',
                errors: v.getValidationErrors()
            });
        }
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/warehouse/receiving/${rid}/product/${pid}`,
            'PATCH',
            payload
        );
        return request.send();
    }

    /**
     * Deletes receiving product
     * @param {string} rid Receiving id
     * @param {string} pid Receiving product id
     * @returns {promise} Returns a Promise that, when fulfilled, will either return success status
     * or an Error with the problem
     */
    deleteReceivingProduct(rid, pid) {
        if (!isString(rid) || rid === '') {
            return Promise.reject({
                status: 0,
                message: 'Receiving id was not specified properly'
            });
        }
        if (!isString(pid) || pid === '') {
            return Promise.reject({
                status: 0,
                message: 'Receiving product id was not specified properly'
            });
        }
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/warehouse/receiving/${rid}/product/${pid}`,
            'DELETE'
        );
        return request.send();
    }
}