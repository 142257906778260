import { isObject } from '../../../helpers/utils';
import { Validator } from '../../core';

const PRODUCT_EXPIRATION_SCHEMA = {
    expiration_time: { type: 'integer', rules: { required: true } },
    expiration_measurement: { type: 'string', rules: { required: true } }
};

const PRODUCT_MEASUREMENT_SCHEMA = {
    unit: { type: 'string', rules: { required: true } },
    value: { type: 'number', rules: { required: true } }
};

const PRODUCT_NUTRITION_VALUE_SCHEMA = {
    id: { type: 'string', rules: { required: true } },
    type: { type: 'string', rules: { required: true } },
    unit: { type: 'string', rules: { required: true } },
    value: { type: 'number', rules: { required: true } }
};

/**
 * Represents meta data record containing product's expiration info.
 * 
 * @class
 */
export class ProductExpiration {
    constructor() {
        this.validator = new Validator();
        // JSON properties
        this._expirationTime = null;
        this._expirationMeasurement = null;
    }

    loadFromJSON(data) {
        if (!isObject(data)) {
            return;
        }
        // Use json property key as setter method for class property
        for (let i in data) {
            this[i] = data[i];
        }
    }

    /*  SETTER METHODS */

    set expiration_time(time) {
        this._expirationTime = time;
    }

    set expiration_measurement(measurement) {
        this._expirationMeasurement = measurement;
    }

    /* GETTER METHODS */

    get expiration_time() {
        return this._expirationTime;
    }

    get expiration_measurement() {
        return this._expirationMeasurement;
    }

    validate() {
        return this.validator.validate(PRODUCT_EXPIRATION_SCHEMA, this);
    }

    getValidationErrors() {
        return this.validator.getValidationErrors();
    }
}

/**
 * Represents meta data record containing product's measurement info.
 * 
 * @class
 */
export class ProductMeasurement {
    constructor() {
        this.validator = new Validator();
        // JSON properties
        this._unit = null;
        this._value = null;
    }

    loadFromJSON(data) {
        if (!isObject(data)) {
            return;
        }
        // Use json property key as setter method for class property
        for (let i in data) {
            this[i] = data[i];
        }
    }

    /* SETTER METHODS */

    set unit(unit) {
        this._unit = unit;
    }

    set value(value) {
        this._value = value;
    }

    /* GETTER METHODS */

    get unit() {
        return this._unit;
    }

    get value() {
        return this._value;
    }

    validate() {
        return this.validator.validate(PRODUCT_MEASUREMENT_SCHEMA, this);
    }

    getValidationErrors() {
        return this.validator.getValidationErrors();
    }
}

/**
 * Represents meta data record containing product's nutrition values.
 * 
 * @class
 */
export class ProductNutritionValue {
    constructor() {
        this.validator = new Validator();
        // JSON properties
        this._id = null;
        this._type = null;
        this._unit = null;
        this._value = null;
    }

    loadFromJSON(data) {
        if (!isObject(data)) {
            return;
        }
        // Use json property key as setter method for class property
        for (let i in data) {
            this[i] = data[i];
        }
    }

    /* SETTER METHODS */

    set id(id) {
        this._id = id;
    }

    set type(type) {
        this._type = type;
    }

    set unit(unit) {
        this._unit = unit;
    }

    set value(value) {
        this._value = value;
    }

    /* GETTER METHODS */

    get id() {
        return this._id;
    }

    get type() {
        return this._type;
    }

    get unit() {
        return this._unit;
    }

    get value() {
        return this._value;
    }

    validate() {
        return this.validator.validate(PRODUCT_NUTRITION_VALUE_SCHEMA, this);
    }

    getValidationErrors() {
        return this.validator.getValidationErrors();
    }
}