import Product from '../Product';
import {
    isObject,
    isNullOrUndefined
} from '../../../helpers/utils';
import { Validator } from '../../core';
import {
    ProductExpiration,
    ProductMeasurement
} from './Common';

const COSMETICS_META_SCHEMA = {
    cosmetics_expiration: { type: 'classInstance', rules: { required: true } },
    cosmetics_allergens: { type: 'array', rules: { itemsType: 'string' } },
    cosmetics_packaging: { type: 'string', rules: { required: true } },
    cosmetics_ingredients: { type: 'array', rules: { itemsType: 'string' } },
    cosmetics_measurement: { type: 'classInstance' },
    cosmetics_gross_weight_measurement: { type: 'classInstance' }
};

/**
 * Describes cosmetics product meta properties.
 * Used with 'cosmetics' product type.
 * 
 * @class
 */
export default class Cosmetics extends Product {

    constructor() {
        super();
        // JSON properties
        this._meta = null;
    }

    /* SETTER METHODS */

    set meta(meta) {
        if (isNullOrUndefined(meta) || !isObject(meta)) {
            return;
        }
        this._meta = new CosmeticsMeta();
        this._meta.loadFromJSON(meta);
    }

    /* GETTER METHODS */

    get meta() {
        return this._meta;
    }
}

class CosmeticsMeta {
    constructor() {
        this.validator = new Validator();
        // JSON properties
        this._cosmeticsAllergens = null;
        this._cosmeticsExpiration = null;
        this._cosmeticsIngredients = null;
        this._cosmeticsMeasurement = null;
        this._cosmeticsPackaging = null;
        this._cosmeticsGrossWeightMeasurement = null;
    }

    loadFromJSON(data) {
        if (!isObject(data)) {
            return;
        }
        // Use json property key as setter method for class property
        for (let i in data) {
            this[i] = data[i];
        }
    }

    /* SETTER METHODS */

    set cosmetics_allergens(cosmeticsAllergens) {
        this._cosmeticsAllergens = cosmeticsAllergens;
    }

    set cosmetics_expiration(cosmeticsExpiration) {
        if (isNullOrUndefined(cosmeticsExpiration) || !isObject(cosmeticsExpiration)) {
            return;
        }
        this._cosmeticsExpiration = new ProductExpiration();
        this._cosmeticsExpiration.loadFromJSON(cosmeticsExpiration);
    }

    set cosmetics_ingredients(cosmeticsIngredients) {
        this._cosmeticsIngredients = cosmeticsIngredients;
    }

    set cosmetics_measurement(cosmeticsMeasurement) {
        if (isNullOrUndefined(cosmeticsMeasurement) || !isObject(cosmeticsMeasurement)) {
            return;
        }
        this._cosmeticsMeasurement = new ProductMeasurement();
        this._cosmeticsMeasurement.loadFromJSON(cosmeticsMeasurement);
    }

    set cosmetics_packaging(cosmeticsPackaging) {
        this._cosmeticsPackaging = cosmeticsPackaging;
    }

    set cosmetics_gross_weight_measurement(cosmeticsGrossWeightMeasurement) {
        if (isNullOrUndefined(cosmeticsGrossWeightMeasurement) || !isObject(cosmeticsGrossWeightMeasurement)) {
            return;
        }
        this._cosmeticsGrossWeightMeasurement = new ProductMeasurement();
        this._cosmeticsGrossWeightMeasurement.loadFromJSON(cosmeticsGrossWeightMeasurement);
    }

    /* GETTER METHODS */

    get cosmetics_allergens() {
        return this._cosmeticsAllergens;
    }

    get cosmetics_expiration() {
        return this._cosmeticsExpiration;
    }

    get cosmetics_ingredients() {
        return this._cosmeticsIngredients;
    }

    get cosmetics_measurement() {
        return this._cosmeticsMeasurement;
    }

    get cosmetics_packaging() {
        return this._cosmeticsPackaging;
    }

    get cosmetics_gross_weight_measurement() {
        return this._cosmeticsGrossWeightMeasurement;
    }

    validate() {
        return this.validator.validate(COSMETICS_META_SCHEMA, this);
    }

    getValidationErrors() {
        return this.validator.getValidationErrors();
    }
}