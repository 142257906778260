import Dexie from 'dexie';
import { Singleton, Validator } from './core';
import { AuthService } from './auth';
import { ProductsService } from './product';
import { CategoriesService } from './category';
import { CompaniesService } from './company';
import { UsersService } from './user';
import { ShoppingCartService } from './shopping-cart';
import { ListsService } from './lists';
import { BookmarksService } from './bookmark';
import { StatsService } from './statistics';
import { PagesService } from './page';
import { OrdersService } from './order';
import { PricesService } from './price';
import { PromotionsService } from './promotion';
import { FormsService } from './form';
import { WarehouseService } from './warehouse';
import { LoggerService } from './error-logger';
import { SSE } from './sse';
import { WebPushSubscriber } from './webpush-subscriber';
import {
    isArray,
    isObject,
    isNullOrUndefined
} from '../helpers/utils';

export class Oblax extends Singleton {

    constructor(config) {
        // call to the constructor of the Singleton class
        super();

        this.apiKey = config.apiKey;
        this.baseUrl = config.baseUrl;
        this.auth = new AuthService(this);
        this.products = new ProductsService(this);
        this.categories = new CategoriesService(this);
        this.companies = new CompaniesService(this);
        this.users = new UsersService(this);
        this.cart = new ShoppingCartService(this);
        this.lists = new ListsService(this);
        this.bookmarks = new BookmarksService(this);
        this.stats = new StatsService(this);
        this.pages = new PagesService(this);
        this.orders = new OrdersService(this);
        this.prices = new PricesService(this);
        this.promotions = new PromotionsService(this);
        this.forms = new FormsService(this);
        this.warehouse = new WarehouseService(this);
        // this.logger = new LoggerService(this);
        this.sse = new SSE(this);
        this.webpushSubscriber = new WebPushSubscriber(this);
        this.validator = new Validator();
        this.db = new Dexie('Oblax');

        this.db.version(1).stores({
            products: 'id, *tags, name',
            categories: 'id, parent_id'
        });

        this.db.version(2).stores({
            _errors: '++id'
        });
    }

    /**
     * 
     */
    syncData() {
        var syncData = null;
        return this.getData()
            .then(res => {
                if (!res || !isArray(res) || res.length == 0) {
                    return Promise.reject({
                        status: 0,
                        message: 'Fetched data is invalid.'
                    });
                }
                syncData = res;
                let clearMethods = [];

                if (
                    isObject(syncData[0]) &&
                    !isNullOrUndefined(syncData[0].data) &&
                    isArray(syncData[0].data) &&
                    syncData[0].data.length > 0
                ) {
                    clearMethods.push(this.products.clearSyncedProducts())
                }

                if (isObject(syncData[0]) &&
                    !isNullOrUndefined(syncData[0].data) &&
                    isArray(syncData[0].data) &&
                    syncData[0].data.length > 0
                ) {
                    clearMethods.push(this.categories.clearSyncedCategories())
                }
                return this.removeSyncedData(clearMethods);
            })
            .then(() => {
                return Promise.all([
                    this.products.syncProductsIntoDB(syncData[0].data),
                    this.categories.syncCategoriesIntoDB(syncData[1].data)
                ]);
            })
            .catch(error => Promise.reject(error));
    }

    /**
     * 
     */
    getData() {
        return Promise.all([
            this.products.getAll(),
            this.categories.getAll()
        ])
            .then(res => res)
            .catch(err => Promise.reject(err));
    }

    removeSyncedData(clearMethods) {
        return Promise.all(clearMethods)
            .then(res => res)
            .catch(err => Promise.reject(err));
    }
}