import {
    isObject,
    objectKeysToSnakeCase,
    extractJsonProps
} from '../../../helpers/utils';
import { Validator } from '../../core';

const MESSAGE_FORM_SCHEMA = {
    id: { type: 'string' },
    name: { type: 'string', rules: { required: true } },
    permalink: { type: 'string', rules: { required: true } },
    fields: { type: 'array', rules: { required: true, itemsType: 'string' } },
    email_response: { type: 'boolean' },
    email_template: { type: 'string' },
    notification_email: { type: 'boolean' },
    notification_slack: { type: 'boolean' }
};

export default class MessageForm {

    constructor() {
        this.validator = new Validator();
        // JSON properties
        this._id = null;
        this._name = null;
        this._permalink = null;
        this._fields = null;
        this._emailResponse = null;
        this._emailTemplate = null;
        this._notificationEmail = null;
        this._notificationSlack = null;
    }

    loadToJSON() {
        // get all properties, ignore methods
        var data = JSON.parse(JSON.stringify(this));
        // extract properties that are part of the json
        extractJsonProps(data);
        return objectKeysToSnakeCase(data);
    }

    loadFromJSON(data) {
        if (!isObject(data)) {
            throw new Error('Message form was not specified properly.');
        }
        // Use json property key as setter method for class property
        for (let i in data) {
            this[i] = data[i];
        }
    }

    /* SETTER METHODS */
    set id(id) {
        this._id = id;
    }

    set name(name) {
        this._name = name;
    }

    set permalink(permalink) {
        this._permalink = permalink;
    }

    set fields(fields) {
        this._fields = fields;
    }

    set email_response(emailResponse) {
        this._emailResponse = emailResponse;
    }

    set email_template(emailTemplate) {
        this._emailTemplate = emailTemplate;
    }

    set notification_email(notificationEmail) {
        this._notificationEmail = notificationEmail;
    }

    set notification_slack(notificationSlack) {
        this._notificationSlack = notificationSlack;
    }

    /* GETTER METHODS */
    get id() {
        return this._id;
    }

    get name() {
        return this._name;
    }

    get permalink() {
        return this._permalink;
    }

    get fields() {
        return this._fields;
    }

    get email_response() {
        return this._emailResponse;
    }

    get email_template() {
        return this._emailTemplate;
    }

    get notification_email() {
        return this._notificationEmail;
    }

    get notification_slack() {
        return this._notificationSlack;
    }

    validate() {
        if (this.validator.validate(MESSAGE_FORM_SCHEMA, this)) {
            return true;
        }
        return false;
    }

    getValidationErrors() {
        return this.validator.getValidationErrors();
    }
}