import { APICall, Validator } from '../core';
import {
    isArray,
    isString,
    isBoolean
} from '../../helpers/utils';
import Category from './Category';

export default class CategoriesService {

    constructor(bc) {
        this.bc = bc;
    }

    /**
     * 
     * @param {Boolean} tree If true, return categories as a tree structure
     */
    getAll(tree) {
        let returnTree = '';
        if (isBoolean(tree) && tree) {
            returnTree = '?format=tree';
        }
        const request = new APICall(
            this.bc.apiKey,
            null,
            `${this.bc.baseUrl}/v1/products/categories${returnTree}`,
            'GET',
        );
        return request.send();
    }

    /**
     * 
     * @param {*} id 
     */
    getCategoryById(id) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Category id was not specified properly.'
            });
        }
        const request = new APICall(
            this.bc.apiKey,
            null,
            `${this.bc.baseUrl}/v1/products/categories/${id}`,
            'GET',
        );
        return request.send();
    }

    /**
     * 
     * @param {*} categories 
     */
    syncCategoriesIntoDB(categories) {
        if (!isArray(categories) || categories.length == 0) {
            return Promise.reject({
                status: 0,
                message: 'Categories list was not specified properly.'
            });
        }
        return this.bc.db.categories
            .bulkPut(categories)
            .then(() => {
                return {
                    status: 1,
                    message: 'Categories were successfully synced into IndexedDB.'
                };
            })
            .catch(error => Promise.reject(error));
    }

    /**
     * 
     */
    clearSyncedCategories() {
        return this.bc.db.categories.clear()
            .then(() => {
                return Promise.resolve({
                    status: 1,
                    message: 'Synced categories were successfully cleared from IndexedDB.'
                });
            })
            .catch(error => Promise.reject(error));
    }

    /**
     * 
     */
    getCategoriesTreeSynced() {
        let promise = this.getAllCategoriesSynced();
        return promise
            .then(categories => {
                if (!isArray(categories) || categories.length == 0) {
                    return Promise.reject({
                        status: 0,
                        message: 'There are no synced categories'
                    });
                }
                return this.buildCategoriesTree(categories, ''); // All first level categories have '' as a parent id
            })
            .catch(e => Promise.reject(e));
    }

    buildCategoriesTree(categories, parent) {
        var tree = [];
        for (let i in categories) {
            if (categories[i].parent_id === parent) {
                categories[i].children = this.buildCategoriesTree(categories, categories[i].id);
                tree.push(categories[i]);
            }
        }
        return tree;
    }

    /**
     * 
     */
    getAllCategoriesSynced() {
        return this.bc.db.categories.toArray();
    }

    /**
     * Insert new category.
     * 
     * @param {object} c Instance of the Category class
     * @return {Promise} 
     */
    insertCategory(c) {
        if (!(c instanceof Category)) {
            return Promise.reject({
                status: 0,
                message: 'Category data argument must be an instance of the Category class.'
            });
        }
        if (!c.validate()) {
            return Promise.reject({
                status: 0,
                message: 'Invalid category data.',
                errors: c.getValidationErrors()
            });
        }
        let payload = c.loadToJSON();
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/products/categories`,
            'POST',
            payload
        );
        return request.send();
    }

    /**
     * Update category.
     * 
     * @param {string} id Category id
     * @param {object} c Instance of the Category class
     * @return {Promise} 
     */
    updateCategory(id, c) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Category id was not specified properly.'
            });
        }
        if (!(c instanceof Category)) {
            return Promise.reject({
                status: 0,
                message: 'Category data argument must be an instance of the Category class.'
            });
        }
        if (!c.validate()) {
            return Promise.reject({
                status: 0,
                message: 'Invalid category data.',
                errors: c.getValidationErrors()
            });
        }
        let payload = c.loadToJSON();
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/products/categories/${id}`,
            'PUT',
            payload
        );
        return request.send();
    }

    /**
     * Delete category.
     * 
     * @param {string} id Category id
     * @return {Promise} 
     */
    deleteCategory(id) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Category id was not specified properly.'
            });
        }
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/products/categories/${id}`,
            'DELETE'
        );
        return request.send();
    }

    /**
     * Updates category's list of products.
     * This methods allows reordering the ids in the list, but the list can not be altered.
     * 
     * @param {string} id Category id 
     * @param {array} products Array of product ids
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return a success status
     * or an Error with the problem.
     */
    updateCategoryProducts(id, products) {
        const SCHEMA = {
            id: { type: 'string', rules: { required: true } },
            products: { type: 'array', rules: { required: true, itemsType: 'string' } }
        };
        let v = new Validator();
        if (!v.validate(SCHEMA, { id, products })) {
            return Promise.reject({
                status: 0,
                message: 'Invalid data',
                errors: v.getValidationErrors()
            });
        }
        let payload = { products };
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/products/categories/${id}/products`,
            'PATCH',
            payload
        );
        return request.send();
    }
}