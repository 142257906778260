import { APICall } from '../core';
import {
    isString
} from '../../helpers/utils';
import Price from './Price';

export default class PricesService {

    constructor(bc) {
        this.bc = bc;
    }

    /**
     * 
     * @param {String} pid Product id
     */
    getAll(pid) {
        if (!isString(pid) || pid === '') {
            return Promise.reject({
                status: 0,
                message: 'Product id was not specified properly.'
            });
        }
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/products/${pid}/prices`,
            'GET',
        );
        return request.send();
    }

    /**
     * 
     * @param {String} pid Product id
     * @param {String} id Price id
     */
    getPriceById(pid, id) {
        if (!isString(pid) || pid === '') {
            return Promise.reject({
                status: 0,
                message: 'Product id was not specified properly.'
            });
        }
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Price id was not specified properly.'
            });
        }
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/products/${pid}/prices/${id}`,
            'GET',
        );
        return request.send();
    }

    /**
     * 
     * @param {String} pid Product id
     * @param {Object} p Instance of the Price class
     */
    insertPrice(pid, p) {
        if (!isString(pid) || pid === '') {
            return Promise.reject({
                status: 0,
                message: 'Product id was not specified properly.'
            });
        }
        if (!(p instanceof Price)) {
            return Promise.reject({
                status: 0,
                message: 'Price data argument must be an instance of the Price class.'
            });
        }
        if (!p.validate()) {
            return Promise.reject({
                status: 0,
                message: 'Invalid price data.',
                errors: p.getValidationErrors()
            });
        }
        let payload = p.loadToJSON();
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/products/${pid}/prices`,
            'POST',
            payload
        );
        return request.send();
    }

    /**
     * 
     * @param {String} pid Product id
     * @param {String} id Price id
     * @param {Object} p Instance of the Price class
     */
    updatePrice(pid, id, p) {
        if (!isString(pid) || pid === '') {
            return Promise.reject({
                status: 0,
                message: 'Product id was not specified properly.'
            });
        }
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Price id was not specified properly.'
            });
        }
        if (!(p instanceof Price)) {
            return Promise.reject({
                status: 0,
                message: 'Price data argument must be an instance of the Price class.'
            });
        }
        if (!p.validate()) {
            return Promise.reject({
                status: 0,
                message: 'Invalid price data.',
                errors: p.getValidationErrors()
            });
        }
        let payload = p.loadToJSON();
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/products/${pid}/prices/${id}`,
            'PUT',
            payload
        );
        return request.send();
    }

    /**
     * 
     * @param {String} pid Product id
     * @param {String} id Price id
     */
    deletePrice(pid, id) {
        if (!isString(pid) || pid === '') {
            return Promise.reject({
                status: 0,
                message: 'Product id was not specified properly.'
            });
        }
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Price id was not specified properly.'
            });
        }
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/products/${pid}/prices/${id}`,
            'DELETE'
        );
        return request.send();
    }
}