import {
    isObject,
    objectKeysToSnakeCase,
    extractJsonProps
} from '../../helpers/utils';
import { Validator } from '../core';

const BOOKMARK_SCHEMA = {
    id: { type: 'string' },
    type: { type: 'string', rules: { required: true } },
    record_id: { type: 'string', rules: { required: true } },
    user_id: { type: 'string', rules: { required: true } },
    title: { type: 'string' }
};

export default class Bookmark {

    constructor() {
        this.validator = new Validator();
        /* JSON properties */
        this._id = null;
        this._type = null;
        this._recordId = null;
        this._userId = null;
        this._title = null;
    }

    loadToJSON() {
        // get all properties, ignore methods
        var data = JSON.parse(JSON.stringify(this));
        // extract properties that are part of the json
        extractJsonProps(data);
        return objectKeysToSnakeCase(data);
    }

    loadFromJSON(data) {
        if (!isObject(data)) {
            throw new Error('Bookmark data was not specified properly.');
        }
        // Use json properties keys as setter methods for class properties
        for (let i in data) {
            this[i] = data[i];
        }
    }

    /* SETTER METHODS */

    set id(id) {
        this._id = id;
    }

    set type(type) {
        this._type = type;
    }

    set record_id(recordId) {
        this._recordId = recordId;
    }

    set user_id(userId) {
        this._userId = userId;
    }

    set title(title) {
        this._title = title;
    }

    /* GETTER METHODS */

    get id() {
        return this._id;
    }

    get type() {
        return this._type;
    }

    get record_id() {
        return this._recordId;
    }

    get user_id() {
        return this._userId;
    }

    get title() {
        return this._title;
    }

    validate() {
        if (this.validator.validate(BOOKMARK_SCHEMA, this)) {
            return true;
        }
        return false;
    }

    getValidationErrors() {
        return this.validator.getValidationErrors();
    }
}