import { APICall } from '../core';
import { isString, isNullOrUndefined } from '../../helpers/utils';
import CartItem from './CartItem';

export default class ShoppingCartService {

    constructor(bc) {
        this.bc = bc;
    }

    /**
     * Gets user's shopping carts.
     * The user must be logged in in order to get his shopping carts.
     * 
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return an Array with the
     * user's shopping carts or an Error with the problem.
     */
    getAllCarts() {
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/cart`,
            'GET',
        );
        return request.send();
    }

    /**
     * Gets user's shopping cart with specified id.
     * The user must be logged in in order to get a cart by id.
     * 
     * @param {String} id Shopping cart id
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return an Object with the
     * user's shopping cart data or an Error with the problem.
     */
    getCart(id) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Cart id was not specified properly.'
            });
        }
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/cart/${id}`,
            'GET',
        );
        return request.send();
    }

    /**
     * Gets the user's shopping cart. If a cart doesn't exists, a new cart will be created.
     * The response contains the cart which is connected to the user's id or his visitor id.
     * When a user logs in, the "visitor id" cart is transformed to a "user id" one.
     * 
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return an Object with the
     * shopping cart data or an Error with the problem.
     */
    getCurrentCart() {
        const request = new APICall(
            this.bc.apiKey,
            !this.bc.auth.jwtData ? null : this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/cart/current`,
            'GET',
        );
        return request.send();
    }

    /**
     * Gets all items in a given cart specified by id.
     * 
     * @param {String} id Shopping cart id
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return an Array with the
     * shopping cart items or an Error with the problem.
     */
    getCartItems(id) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Cart id was not specified properly.'
            });
        }
        const request = new APICall(
            this.bc.apiKey,
            !this.bc.auth.jwtData ? null : this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/cart/${id}/items`,
            'GET',
        );
        return request.send();
    }

    /**
     * Adds new item into the shopping cart specified by id.
     * 
     * @param {String} id Shopping cart id
     * @param {Object} ci Instance of the CartItem class
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return an Object with the
     * cart item data or an Error with the problem.
     */
    addCartItem(id, ci) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Cart id was not specified properly.'
            });
        }
        if (!(ci instanceof CartItem)) {
            return Promise.reject({
                status: 0,
                message: 'Cart item data argument must be an instance of the CartItem class.'
            });
        }
        if (!ci.validate()) {
            return Promise.reject({
                status: 0,
                message: 'Invalid cart item data.',
                errors: ci.getValidationErrors()
            });
        }
        let payload = ci.loadToJSON();
        const request = new APICall(
            this.bc.apiKey,
            !this.bc.auth.jwtData ? null : this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/cart/${id}/items`,
            'POST',
            payload
        );
        return request.send();
    }

    /**
     * Updates cart item data for a shopping cart specified by id.
     * This is PATCH method and only the provided properties will be updated.
     * 
     * @param {string} id Shopping cart id
     * @param {String} iid Cart item id
     * @param {Object} ci Instance of the CartItem class
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return an Object with the
     * cart item data or an Error with the problem.
     */
    updateCartItem(id, iid, ci) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Cart id was not specified properly.'
            });
        }
        if (!isString(iid) || iid === '') {
            return Promise.reject({
                status: 0,
                message: 'Cart item id was not specified properly.'
            });
        }
        if (!(ci instanceof CartItem)) {
            return Promise.reject({
                status: 0,
                message: 'Cart item data argument must be an instance of the CartItem class.'
            });
        }
        if (!ci.validate()) {
            return Promise.reject({
                status: 0,
                message: 'Invalid cart item data.',
                errors: ci.getValidationErrors()
            });
        }
        let payload = ci.loadToJSON();
        const request = new APICall(
            this.bc.apiKey,
            !this.bc.auth.jwtData ? null : this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/cart/${id}/items/${iid}`,
            'PATCH',
            payload
        );
        return request.send();
    }

    /**
     * Deletes cart item from the shopping cart specified by id.
     * 
     * @param {String} id Shopping cart id
     * @param {String} iid Cart item id
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return a  success status 
     * or an Error with the problem.
     */
    deleteCartItem(id, iid) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Cart id was not specified properly.'
            });
        }
        if (!isString(iid) || iid === '') {
            if (!isString(iid) || iid === '') {
                return Promise.reject({
                    status: 0,
                    message: 'Cart item id was not specified properly.'
                });
            }
        }
        const request = new APICall(
            this.bc.apiKey,
            !this.bc.auth.jwtData ? null : this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/cart/${id}/items/${iid}`,
            'DELETE'
        );
        return request.send();
    }

    /**
     * Gets all shopping lists.
     * 
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return an Array with the
     * logged in user shopping lists or an Error with the problem.
     */
    getLists() {
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/cart/list`,
            'GET',
        );
        return request.send();
    }

    /**
     * Creates new shopping list.
     * 
     * @param {String} name Shopping list name
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return a  success status 
     * or an Error with the problem.
     */
    createList(name) {
        if (isNullOrUndefined(name) || !isString(name)) {
            return Promise.reject({
                status: 0,
                message: 'List name was not specified properly.'
            });
        }
        let payload = { name: name };
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/cart/list`,
            'POST',
            payload
        );
        return request.send();
    }

    /**
     * Deletes shopping list.
     * 
     * @param {String} id Shopping list id
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return a  success status 
     * or an Error with the problem.
     */
    deleteList(id) {
        if (isNullOrUndefined(id) || !isString(id)) {
            return Promise.reject({
                status: 0,
                message: 'List id was not specified properly.'
            });
        }
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/cart/list/${id}`,
            'DELETE'
        );
        return request.send();
    }
}