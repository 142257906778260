import { APICall } from '../core';
import {
    isString
} from '../../helpers/utils';
import Promotion from './Promotion';

export default class PromotionsService {

    constructor(bc) {
        this.bc = bc;
    }

    /**
     * 
     * @param {String} pid Product id
     */
    getAll(pid) {
        if (!isString(pid) || pid === '') {
            return Promise.reject({
                status: 0,
                message: 'Product id was not specified properly.'
            });
        }
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/products/${pid}/promotions`,
            'GET',
        );
        return request.send();
    }

    /**
     * 
     * @param {String} pid Product id
     * @param {String} id Promotion id
     */
    getPromotionById(pid, id) {
        if (!isString(pid) || pid === '') {
            return Promise.reject({
                status: 0,
                message: 'Product id was not specified properly.'
            });
        }
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Promotion id was not specified properly.'
            });
        }
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/products/${pid}/promotions/${id}`,
            'GET',
        );
        return request.send();
    }

    /**
     * 
     * @param {String} pid Product id
     * @param {Object} p Instance of the Promotion class
     */
    insertPromotion(pid, p) {
        if (!isString(pid) || pid === '') {
            return Promise.reject({
                status: 0,
                message: 'Product id was not specified properly.'
            });
        }
        if (!(p instanceof Promotion)) {
            return Promise.reject({
                status: 0,
                message: 'Promotion data argument must be an instance of the Promotion class.'
            });
        }
        if (!p.validate()) {
            return Promise.reject({
                status: 0,
                message: 'Invalid promotion data.',
                errors: p.getValidationErrors()
            });
        }
        let payload = p.loadToJSON();
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/products/${pid}/promotions`,
            'POST',
            payload
        );
        return request.send();
    }

    /**
     * 
     * @param {String} pid Product id
     * @param {String} id Promotion id
     * @param {Object} p Instance of the Promotion class
     */
    updatePromotion(pid, id, p) {
        if (!isString(pid) || pid === '') {
            return Promise.reject({
                status: 0,
                message: 'Product id was not specified properly.'
            });
        }
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Promotion id was not specified properly.'
            });
        }
        if (!(p instanceof Promotion)) {
            return Promise.reject({
                status: 0,
                message: 'Promotion data argument must be an instance of the Promotion class.'
            });
        }
        if (!p.validate()) {
            return Promise.reject({
                status: 0,
                message: 'Invalid promotion data.',
                errors: p.getValidationErrors()
            });
        }
        let payload = p.loadToJSON();
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/products/${pid}/promotions/${id}`,
            'PUT',
            payload
        );
        return request.send();
    }

    /**
     * 
     * @param {String} pid Product id
     * @param {String} id Promotion id
     */
    deletePromotion(pid, id) {
        if (!isString(pid) || pid === '') {
            return Promise.reject({
                status: 0,
                message: 'Product id was not specified properly.'
            });
        }
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Promotion id was not specified properly.'
            });
        }
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/products/${pid}/promotions/${id}`,
            'DELETE'
        );
        return request.send();
    }
}