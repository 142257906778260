import {
    isObject,
    objectKeysToSnakeCase,
    extractJsonProps
} from '../../helpers/utils';
import { Validator } from '../core';

const DELIVERY_SCHEDULE_SCHEMA = {
    id: { type: 'string' },
    type: { type: 'string', rules: { required: true } },
    date_start: { type: 'date', rules: { required: false } },
    date_end: { type: 'date', rules: { required: false } },
    day: { type: 'number', rules: { required: false } },
    repeat: { type: 'boolean', rules: { required: false } }
};

export default class DeliverySchedule {

    constructor() {
        this.validator = new Validator();
        /* JSON properties */
        this._id = null;
        this._type = null;
        this._dateStart = null;
        this._dateEnd = null;
        this._day = null;
        this._repeat = null;
    }

    loadToJSON() {
        // get all properties, ignore methods
        var data = JSON.parse(JSON.stringify(this));
        // extract properties that are part of the json
        extractJsonProps(data);
        return objectKeysToSnakeCase(data);
    }

    loadFromJSON(data) {
        if (!isObject(data)) {
            throw new Error('Delivery schedule data was not specified properly.');
        }
        // Use json properties keys as setter methods for class properties
        for (let i in data) {
            this[i] = data[i];
        }
    }

    /* SETTER METHODS */

    set id(id) {
        this._id = id;
    }

    set type(type) {
        this._type = type;
    }
    
    set date_start(dateStart) {
        this._dateStart = dateStart;
    }

    set date_end(dateEnd) {
        this._dateEnd = dateEnd;
    }

    set day(day) {
        this._day = day;
    }

    set repeat(repeat) {
        this._repeat = repeat;
    }

    /* GETTER METHODS */

    get id() {
        return this._id;
    }

    get type() {
        return this._type;
    }
    
    get date_start() {
        return this._dateStart;
    }

    get date_end() {
        return this._dateEnd;
    }

    get day() {
        return this._day;
    }
    
    get repeat() {
        return this._repeat;
    }

    validate() {
        if (this.validator.validate(DELIVERY_SCHEDULE_SCHEMA, this)) {
            return true;
        }
        return false;
    }

    getValidationErrors() {
        return this.validator.getValidationErrors();
    }
}