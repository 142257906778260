import { isString } from '../../helpers/utils';
import {
    Food,
    Beverage,
    Cosmetics,
    PersonalHygiene,
    Cleaning
} from './variation'

export default class ProductFactory {
    static createProduct(type) {
        if (!isString(type)) {
            throw new Error('Product type is missing or it was not specified properly.');
        }
        switch (type) {
            case 'food':
                return new Food();
            case 'beverage':
                return new Beverage();
            case 'cosmetics':
                return new Cosmetics();
            case 'personal_hygiene':
                return new PersonalHygiene();
            case 'cleaning':
                return new Cleaning();
            default:
                throw new Error('Invalid product type provided.');
        }
    }
}