import {
    isObject,
    objectKeysToSnakeCase,
    extractJsonProps
} from '../../../helpers/utils';
import { Validator } from '../../core';

const MESSAGE_FORM_DATA_SCHEMA = {
    id: { type: 'string' },
    form_id: { type: 'string' },
    name: { type: 'string' },
    email: { type: 'string' },
    title: { type: 'string' },
    content: { type: 'string' },
    files: { type: 'array', rules: { itemsType: 'string' } },
    topic: { type: 'string' },
    phone: { type: 'string' },
    address: { type: 'string' }
};

export default class MessageFormData {

    constructor() {
        this.validator = new Validator();
        // JSON properties
        this._id = null;
        this._formId = null;
        this._name = null;
        this._email = null;
        this._title = null;
        this._content = null;
        this._files = null;
        this._topic = null;
        this._phone = null;
        this._address = null;
    }

    loadToJSON() {
        // get all properties, ignore methods
        var data = JSON.parse(JSON.stringify(this));
        // remove all private properties
        extractJsonProps(data);
        return objectKeysToSnakeCase(data);
    }

    loadFromJSON(data) {
        if (!isObject(data)) {
            throw new Error('Message form data was not specified properly.');
        }
        // Use json property key as setter method for class property
        for (let i in data) {
            this[i] = data[i];
        }
    }

    /* SETTER METHODS */
    set id(id) {
        this._id = id;
    }

    set form_id(formId) {
        this._formId = formId;
    }

    set name(name) {
        this._name = name;
    }

    set email(email) {
        this._email = email;
    }

    set title(title) {
        this._title = title;
    }

    set content(content) {
        this._content = content;
    }

    set files(files) {
        this._files = files;
    }

    set topic(topic) {
        this._topic = topic;
    }

    set phone(phone) {
        this._phone = phone;
    }

    set address(address) {
        this._address = address;
    }

    /* GETTER METHODS */
    get id() {
        return this._id;
    }

    get form_id() {
        return this._formId_id;
    }

    get name() {
        return this._name_id;
    }

    get email() {
        return this._email_id;
    }

    get title() {
        return this._title_id;
    }

    get content() {
        return this._content_id;
    }

    get files() {
        return this._files_id;
    }

    get topic() {
        return this._topic_id;
    }

    get phone() {
        return this._phone_id;
    }

    get address() {
        return this._address_id;
    }

    validate() {
        if (this.validator.validate(MESSAGE_FORM_DATA_SCHEMA, this)) {
            return true;
        }
        return false;
    }

    getValidationErrors() {
        return this.validator.getValidationErrors();
    }
}