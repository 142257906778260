import Product from '../Product';
import {
    isArray,
    isObject,
    isNullOrUndefined
} from '../../../helpers/utils';
import { Validator } from '../../core';
import {
    ProductExpiration,
    ProductMeasurement,
    ProductNutritionValue
} from './Common';

const FOOD_META_SCHEMA = {
    food_expiration: { type: 'classInstance', rules: { required: true } },
    food_allergens: { type: 'array', rules: { itemsType: 'string' } },
    food_packaging: { type: 'string', rules: { required: true } },
    food_ingredients: { type: 'array', rules: { itemsType: 'string' } },
    food_measurement: { type: 'classInstance' },
    food_nutrition_values: { type: 'array', rules: { itemsType: 'classInstance' } },
    food_gross_weight_measurement: { type: 'classInstance' }
};

/**
 * Describes food meta properties.
 * Used with 'food' product type.
 * 
 * @class
 */
export default class Food extends Product {

    constructor() {
        super();
        // JSON properties
        this._meta = null;
    }

    /* SETTER METHODS */

    set meta(meta) {
        if (isNullOrUndefined(meta) || !isObject(meta)) {
            return;
        }
        this._meta = new FoodMeta();
        this._meta.loadFromJSON(meta);
    }

    /* GETTER METHODS */

    get meta() {
        return this._meta;
    }
}

class FoodMeta {
    constructor() {
        this.validator = new Validator();
        // JSON properties
        this._foodAllergens = null;
        this._foodExpiration = null;
        this._foodIngredients = null;
        this._foodMeasurement = null;
        this._foodNutritionValues = null;
        this._foodPackaging = null;
        this._foodGrossWeightMeasurement = null;
    }

    loadFromJSON(data) {
        if (!isObject(data)) {
            return;
        }
        // Use json property key as setter method for class property
        for (let i in data) {
            this[i] = data[i];
        }
    }

    /* SETTER METHODS */

    set food_allergens(foodAllergens) {
        this._foodAllergens = foodAllergens;
    }

    set food_expiration(foodExpiration) {
        if (isNullOrUndefined(foodExpiration) || !isObject(foodExpiration)) {
            return;
        }
        this._foodExpiration = new ProductExpiration();
        this._foodExpiration.loadFromJSON(foodExpiration);
    }

    set food_ingredients(foodIngredients) {
        this._foodIngredients = foodIngredients;
    }

    set food_measurement(foodMeasurement) {
        if (isNullOrUndefined(foodMeasurement) || !isObject(foodMeasurement)) {
            return;
        }
        this._foodMeasurement = new ProductMeasurement();
        this._foodMeasurement.loadFromJSON(foodMeasurement);
    }

    set food_nutrition_values(foodNutritionValues) {
        if (isNullOrUndefined(foodNutritionValues) || !isArray(foodNutritionValues)) {
            return;
        }
        this._foodNutritionValues = [];
        for (let i in foodNutritionValues) {
            let fnv = new ProductNutritionValue();
            fnv.loadFromJSON(foodNutritionValues[i]);
            this._foodNutritionValues.push(fnv);
        }
    }

    set food_packaging(foodPackaging) {
        this._foodPackaging = foodPackaging;
    }

    set food_gross_weight_measurement(foodGrossWeightMeasurement) {
        if (isNullOrUndefined(foodGrossWeightMeasurement) || !isObject(foodGrossWeightMeasurement)) {
            return;
        }
        this._foodGrossWeightMeasurement = new ProductMeasurement();
        this._foodGrossWeightMeasurement.loadFromJSON(foodGrossWeightMeasurement);
    }

    /* GETTER METHODS */

    get food_allergens() {
        return this._foodAllergens;
    }

    get food_expiration() {
        return this._foodExpiration;
    }

    get food_ingredients() {
        return this._foodIngredients;
    }

    get food_measurement() {
        return this._foodMeasurement;
    }

    get food_nutrition_values() {
        return this._foodNutritionValues;
    }

    get food_packaging() {
        return this._foodPackaging;
    }

    get food_gross_weight_measurement() {
        return this._foodGrossWeightMeasurement;
    }

    validate() {
        return this.validator.validate(FOOD_META_SCHEMA, this);
    }

    getValidationErrors() {
        return this.validator.getValidationErrors();
    }
}