import {
    isString,
    isNumber,
    isInteger,
    isBoolean,
    isObject,
    isArray,
    isDate,
    isNullOrUndefined
} from '../../helpers/utils';

var StringValidatorError = function (msgKey, key, restriction) {
    const ERRORS_MSGS = {
        'REQUIRED': 'is required property',
        'TYPE_STRING': 'should be type string',
        'STRING_LENGTH': `should contain ${restriction} character${restriction == 1 ? '' : 's'}`,
        'MIN_STRING_LENGTH': `should contain minimum ${restriction} character${restriction == 1 ? '' : 's'}`,
        'MAX_STRING_LENGTH': `should contain maximum ${restriction} character${restriction == 1 ? '' : 's'}`,
        'EMAIL_ADDRESS': `should be valid email address`
    };
    return {
        code: msgKey,
        key: key,
        message: ERRORS_MSGS[msgKey]
    };
};

var NumberValidatorError = function (msgKey, key, restriction) {
    const ERRORS_MSGS = {
        'REQUIRED': 'is required property',
        'TYPE_NUMBER': 'should be type number',
        'TYPE_INTEGER': 'should be type integer',
        'MINIMUM_VALUE': `should be greater then or equal to ${restriction}`,
        'EXCLUSIVE_MINIMUM_VALUE': `should be greater then ${restriction}`,
        'MAXIMUM_VALUE': `should be less then or equal to ${restriction}`,
        'EXLUSIVE_MAXIMUM_VALUE': `should be less then ${restriction}`
    };
    return {
        code: msgKey,
        key: key,
        message: ERRORS_MSGS[msgKey]
    };
};

var ClassInstanceValidatorError = function (msgKey, key) {
    const ERRORS_MSGS = {
        'REQUIRED': 'is required property',
        'TYPE_OBJECT': 'shoud be type object'
    };
    return {
        code: msgKey,
        key: key,
        message: ERRORS_MSGS[msgKey]
    };
};

var ArrayValidatorError = function (msgKey, key, type, restriction) {
    const ERRORS_MSGS = {
        'REQUIRED': 'is required property',
        'TYPE_ARRAY': 'should be type array',
        'ARRAY_ITEM_TYPE': `should be type ${type}`,
        'ARRAY_ITEM_NO_VALIDATE_METHOD': `doesn't implement 'validate' method`,
        'MIN_ITEMS': `should contain minimum ${restriction} item${restriction == 1 ? '' : 's'}`,
        'MAX_ITEMS': `should contain maximum ${restriction} item${restriction == 1 ? '' : 's'}`
    };
    return {
        code: msgKey,
        key: key,
        message: ERRORS_MSGS[msgKey]
    };
};

var DateValidatorError = function (msgKey, key) {
    const ERRORS_MSGS = {
        'REQUIRED': 'is required property',
        'TYPE_DATE': 'should be type date'
    };
    return {
        code: msgKey,
        key: key,
        message: ERRORS_MSGS[msgKey]
    };
};

var BooleanValidatorError = function (msgKey, key) {
    const ERRORS_MSGS = {
        'REQUIRED': 'is required property',
        'TYPE_BOOLEAN': 'should be type boolean'
    };
    return {
        code: msgKey,
        key: key,
        message: ERRORS_MSGS[msgKey]
    };
}

/**
 * STRING VALIDATOR CLASS
 */
class StringValidator {
    constructor(key, value) {
        this.key = key;
        this.value = value;
        // Validator rules
        this._required = false;
        this._length = null;
        this._minLength = null;
        this._maxLength = null;
        this._emailAddress = null;
    }

    /**
     * This method should always return an array with errors
     */
    exec(rules) {
        // First perform required and type check
        let errors = [this.isAssigned(), this.isString()];
        if (errors.filter(e => e !== null).length > 0) {
            return errors;
        }
        // Proceed with remaining rules
        for (let r in rules) {
            switch (rules[r]) {
                case 'length':
                    errors.push(this.validateLength());
                    break;
                case 'minLength':
                    errors.push(this.validateMinLength());
                    break;
                case 'maxLength':
                    errors.push(this.validateMaxLength());
                    break;
                case 'emailAddress':
                    errors.push(this.validateEmailAddress());
                    break;

            }
        }
        return errors;
    }

    set required(required) {
        if (isBoolean(required)) {
            this._required = required;
        }
    }

    set length(length) {
        if (isNumber(length)) {
            this._length = length;
        }
    }

    set minLength(minLength) {
        if (isNumber(minLength)) {
            this._minLength = minLength;
        }
    }

    set maxLength(maxLength) {
        if (isNumber(maxLength)) {
            this._maxLength = maxLength;
        }
    }

    set emailAddress(emailAddress) {
        if (isBoolean(emailAddress)) {
            this._emailAddress = emailAddress;
        }
    }

    isAssigned() {
        if (this._required && isNullOrUndefined(this.value)) {
            return StringValidatorError('REQUIRED', this.key);
        }
        return null;
    }

    isString() {
        // When it is required
        if (this._required && !isString(this.value)) {
            return StringValidatorError('TYPE_STRING', this.key);
        }
        // When it is not required
        if (!this._required && !isNullOrUndefined(this.value) && !isString(this.value)) {
            return StringValidatorError('TYPE_STRING', this.key);
        }
        return null;
    }

    validateLength() {
        if (isString(this.value) && this.value.length != this._length) {
            return StringValidatorError('STRING_LENGTH', this.key, this._length);
        }
        return null;
    }

    validateMinLength() {
        if (isString(this.value) && this.value.length < this._minLength) {
            return StringValidatorError('MIN_STRING_LENGTH', this.key, this._minLength);
        }
        return null;
    }

    validateMaxLength() {
        if (isString(this.value) && this.value.length > this._maxLength) {
            return StringValidatorError('MAX_STRING_LENGTH', this.key, this._maxLength);
        }
        return null;
    }

    validateEmailAddress() {
        let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (isString(this.value) && this._emailAddress && !this.value.match(emailRegex)) {
            return StringValidatorError('EMAIL_ADDRESS', this.key);
        }
        return null;
    }
}

/**
 * NUMBER VALIDATOR CLASS
 */
class NumberValidator {
    constructor(key, value, type) {
        this.key = key;
        this.value = value;
        // Validator rules
        this._type = null; // integer or number (for float), when the type is specified as number it will accept both integer and float values
        this._required = false;
        this._minimum = null; // x >= minimum
        this._exclusiveMinimum = null; // x > minimum 
        this._maximum = null; // x <= maximum
        this._exclusiveMaximum = null; // x < maximum

        // we will set the type property using the setter method
        this.type = type;
    }

    exec(rules) {
        // First perform required and type check
        let typeCheck = this._type == 'integer' ? this.isInteger() : this.isNumber();
        let errors = [this.isAssigned(), typeCheck];
        if (errors.filter(e => e !== null).length > 0) {
            return errors;
        }
        // Proceed with remaining rules
        for (let r in rules) {
            switch (rules[r]) {
                case 'minimum':
                    errors.push(this.validateMinimum());
                    break;
                case 'exclusiveMinimum':
                    errors.push(this.validateExclusiveMinimum());
                    break;
                case 'maximum':
                    errors.push(this.validateMaximum());
                    break;
                case 'exclusiveMaximum':
                    errors.push(this.validateExclusiveMaximum());
                    break;
            }
        }
        return errors;
    }

    set type(type) {
        let allowedTypes = ['number', 'integer'];
        if (isString(type) && allowedTypes.indexOf(type) > -1) {
            this._type = type;
        }
    }

    set required(required) {
        if (isBoolean(required)) {
            this._required = required;
        }
    }

    set minimum(minimum) {
        if (isNumber(minimum)) {
            this._minimum = minimum;
        }
    }

    set exclusiveMinimum(exclusiveMinimum) {
        if (isNumber(exclusiveMinimum)) {
            this._exclusiveMinimum = exclusiveMinimum;
        }
    }

    set maximum(maximum) {
        if (isNumber(maximum)) {
            this._maximum = maximum;
        }
    }

    set exclusiveMaximum(exclusiveMaximum) {
        if (isNumber(exclusiveMaximum)) {
            this._exclusiveMaximum = exclusiveMaximum;
        }
    }

    isAssigned() {
        if (this._required && isNullOrUndefined(this.value)) {
            return NumberValidatorError('REQUIRED', this.key);
        }
        return null;
    }

    isNumber() {
        // When it is required
        if (this._required && !isNumber(this.value)) {
            return NumberValidatorError('TYPE_NUMBER', this.key);
        }
        // When it is not required
        if (!this._required && !isNullOrUndefined(this.value) && !isNumber(this.value)) {
            return NumberValidatorError('TYPE_NUMBER', this.key);
        }
        return null;
    }

    isInteger() {
        // When it is required
        if (this._required && !isInteger(this.value)) {
            return NumberValidatorError('TYPE_INTEGER', this.key);
        }
        // When it is not required
        if (!this._required && !isNullOrUndefined(this.value) && !isInteger(this.value)) {
            return NumberValidatorError('TYPE_INTEGER', this.key);
        }
        return null;
    }

    validateMinimum() {
        if (isNumber(this.value) && this.value >= this._minimum) {
            return null;
        }
        return NumberValidatorError('MINIMUM_VALUE', this.key, this._minimum);
    }

    validateExclusiveMinimum() {
        if (isNumber(this.value) && this.value > this._exclusiveMinimum) {
            return null;
        }
        return NumberValidatorError('EXCLUSIVE_MINIMUM_VALUE', this.key, this._exclusiveMinimum);
    }

    validateMaximum() {
        if (isNumber(this.value) && this.value <= this._maximum) {
            return null;
        }
        return NumberValidatorError('MAXIMUM_VALUE', this.key, this._maximum);
    }

    validateExclusiveMaximum() {
        if (isNumber(this.value) && this.value < this._exclusiveMaximum) {
            return null;
        }
        return NumberValidatorError('EXCLUSIVE_MAXIMUM_VALUE', this.key, this._exclusiveMaximum);
    }
}

/**
 * CLASS INSTANCE VALIDATOR
 */
class ClassInstanceValidator {
    constructor(key, value) {
        this.key = key;
        this.value = value;
        // Validator rules
        this._required = false;
    }

    exec() {
        let errors = [this.isAssigned(), this.isObject()];
        if (errors.filter(e => e !== null).length > 0) {
            return errors;
        }
        errors.push(...this.validateInstance());
        return errors;
    }

    set required(required) {
        if (isBoolean(required)) {
            this._required = required;
        }
    }

    isAssigned() {
        if (this._required && isNullOrUndefined(this.value)) {
            return ClassInstanceValidatorError('REQUIRED', this.key);
        }
        return null;
    }

    isObject() {
        // When it is required
        if (this._required && !isObject(this.value)) {
            return ClassInstanceValidatorError('TYPE_OBJECT', this.key);
        }
        // When it is not required
        if (!this._required && !isNullOrUndefined(this.value) && !isObject(this.value)) {
            return ClassInstanceValidatorError('TYPE_OBJECT', this.key);
        }
        return null;
    }

    validateInstance() {
        let errors = [];
        if (isNullOrUndefined(this.value) || !isObject(this.value)) {
            return errors;
        }
        // check if the instance has the validate method
        if (typeof this.value.validate !== 'function') {
            return errors;
        }
        if (!this.value.validate()) {
            let tmpErrors = [...this.value.getValidationErrors()];
            // prepend the parent key in the error message for better error description
            for (let j in tmpErrors) {
                tmpErrors[j].key = `${this.key}.${tmpErrors[j].key}`;
            }
            errors.push(...tmpErrors);
        }
        return errors;
    }
}

/**
 * ARRAY VALIDATOR CLASS
 */
class ArrayValidator {
    constructor(key, value) {
        this.key = key;
        this.value = value;
        // Validator rules
        this._required = false;
        this._itemsType = null;
        this._minItems = null;
        this._maxItems = null;
    }

    exec(rules) {
        // First perform required and type check
        let errors = [this.isAssigned(), this.isArray()];
        if (errors.filter(e => e !== null).length > 0) {
            return errors;
        }
        // Proceed with remaining rules
        for (let r in rules) {
            switch (rules[r]) {
                case 'itemsType':
                    errors.push(...this.routeItemsTypeValidation());
                    break;
                case 'minItems':
                    errors.push(this.validateMinItems());
                    break;
                case 'maxItems':
                    errors.push(this.validateMaxItems());
                    break;
            }
        }
        return errors;
    }

    set required(required) {
        if (isBoolean(required)) {
            this._required = required;
        }
    }

    set itemsType(itemsType) {
        if (isString(itemsType)) {
            this._itemsType = itemsType;
        }
    }

    set minItems(minItems) {
        if (isNumber(minItems)) {
            this._minItems = minItems;
        }
    }

    set maxItems(maxItems) {
        if (isNumber(maxItems)) {
            this._maxItems = maxItems;
        }
    }

    isAssigned() {
        if (this._required && isNullOrUndefined(this.value)) {
            return ArrayValidatorError('REQUIRED', this.key);
        }
        return null;
    }

    isArray() {
        // When it is required
        if (this._required && !isArray(this.value)) {
            return ArrayValidatorError('TYPE_ARRAY', this.key);
        }
        // When it is not required
        if (!this._required && !isNullOrUndefined(this.value) && !isArray(this.value)) {
            return ArrayValidatorError('TYPE_ARRAY', this.key);
        }
        return null;
    }

    routeItemsTypeValidation() {
        switch (this._itemsType) {
            case 'string':
                return this.stringItemsTypeValidation();
            case 'classInstance':
                return this.classInstanceItemsTypeValidation();
        }
    }

    stringItemsTypeValidation() {
        let errors = [];
        if (isNullOrUndefined(this.value) || !isArray(this.value)) {
            return errors;
        }
        for (let i in this.value) {
            if (!isString(this.value[i])) {
                errors.push(ArrayValidatorError('ARRAY_ITEM_TYPE', `${this.key}[${i}]`, this._itemsType));
            }
        }
        return errors;
    }

    classInstanceItemsTypeValidation() {
        let errors = [];
        if (isNullOrUndefined(this.value) || !isArray(this.value)) {
            return errors;
        }
        for (let i in this.value) {
            if (typeof this.value[i].validate !== 'function') {
                errors.push(ArrayValidatorError('ARRAY_ITEM_NO_VALIDATE_METHOD', `${this.key}[${i}]`));
                continue;
            }
            // check if the instance has the 'validate' method
            if (!this.value[i].validate()) {
                let tmpErrors = [...this.value[i].getValidationErrors()];
                // prepend the array index in the error message for better error description
                for (let j in tmpErrors) {
                    tmpErrors[j].key = `${this.key}[${i}].${tmpErrors[j].key}`;
                }
                errors.push(...tmpErrors);
            }
        }
        return errors;
    }

    validateMinItems() {
        if (isArray(this.value) && this.value.length < this._minItems) {
            return ArrayValidatorError('MIN_ITEMS', this.key, null, this._minItems);
        }
        return null;
    }

    validateMaxItems() {
        if (isArray(this.value) && this.value.length > this._maxItems) {
            return ArrayValidatorError('MAX_ITEMS', this.key, null, this._maxItems);
        }
        return null;
    }
}

/**
 * DATE VALIDATOR CLASS
 */
class DateValidator {
    constructor(key, value) {
        this.key = key;
        this.value = value;
        // Validator rules
        this._required = false;
    }

    /**
     * This method should always return an array with errors
     */
    exec() {
        return [this.isAssigned(), this.isDate()];
    }

    set required(required) {
        if (isBoolean(required)) {
            this._required = required;
        }
    }

    isAssigned() {
        if (this._required && isNullOrUndefined(this.value)) {
            return DateValidatorError('REQUIRED', this.key);
        }
        return null;
    }

    isDate() {
        // When it is required
        if (this._required && !isDate(this.value)) {
            return DateValidatorError('TYPE_DATE', this.key);
        }
        // When it is not required
        if (!this._required && !isNullOrUndefined(this.value) && !isDate(this.value)) {
            return DateValidatorError('TYPE_DATE', this.key);
        }
        return null;
    }
}

/**
 * BOOLEAN VALIDATOR CLASS
 */
class BooleanValidator {
    constructor(key, value) {
        this.key = key;
        this.value = value;
        // Validator rules
        this._required = false;
    }

    /**
     * This method should always return an array with errors
     */
    exec() {
        return [this.isAssigned(), this.isBoolean()];
    }

    set required(required) {
        if (isBoolean(required)) {
            this._required = required;
        }
    }

    isAssigned() {
        if (this._required && isNullOrUndefined(this.value)) {
            return BooleanValidatorError('REQUIRED', this.key);
        }
        return null;
    }

    isBoolean() {
        // When it is required
        if (this._required && !isBoolean(this.value)) {
            return BooleanValidatorError('TYPE_BOOLEAN', this.key);
        }
        // When it is not required
        if (!this._required && !isNullOrUndefined(this.value) && !isBoolean(this.value)) {
            return BooleanValidatorError('TYPE_BOOLEAN', this.key);
        }
        return null;
    }
}

/**
 * MAIN VALIDATOR CLASS
 */
export default class Validator {
    constructor() {
        this._errors = [];
    }

    validate(schema, data) {
        this.resetValidationErrors();
        // Cannot perform validation without valid schema
        if (!schema || !isObject(schema)) {
            throw new Error('Cannot perform validation without valid schema');
        }
        for (let prop in schema) {
            // Cannot perform validation without specified type
            let { type, rules } = schema[prop];
            if (!type) {
                throw new Error(`Cannot validate key '${prop}' without specified type`);
            }
            // Create specific validator instance
            let v = null;
            switch (type) {
                case 'string':
                    v = new StringValidator(prop, data[prop]);
                    break;
                case 'number':
                case 'integer':
                    v = new NumberValidator(prop, data[prop], type);
                    break;
                case 'classInstance':
                    v = new ClassInstanceValidator(prop, data[prop]);
                    break;
                case 'array':
                    v = new ArrayValidator(prop, data[prop]);
                    break;
                case 'date':
                    v = new DateValidator(prop, data[prop]);
                    break;
                case 'boolean':
                    v = new BooleanValidator(prop, data[prop]);
                    break;
                default:
                    // In case of an invalid type, skip the schema entry and go to next loop iteration
                    continue;
            }
            // Populate validation rules
            for (let i in rules) {
                v[i] = rules[i];
            }
            // Execute validation rules
            let rulesKeys = rules ? Object.keys(rules) : [];
            v.exec(rulesKeys).forEach(e => {
                if (e !== null) { this.addValidationError(e) }
            });
        }
        return this._errors.length > 0 ? false : true;
    }

    addValidationError(error) {
        if (isArray(error)) {
            this._errors.push(...error);
        } else {
            this._errors.push(error);
        }
    }

    getValidationErrors() {
        return this._errors;
    }

    resetValidationErrors() {
        this._errors.length = 0;
    }
}