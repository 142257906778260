import {
    isObject,
    objectKeysToSnakeCase,
    extractJsonProps
} from '../../helpers/utils';
import { Validator } from '../core';

const RECEIVING_PRODUCT_SCHEMA = {
    id: { type: 'string' },
    receiving_id: { type: 'string' },
    product_id: { type: 'string', rules: { required: true } },
    quantity: { type: 'integer', rules: { required: true } },
    price: { type: 'number', rules: { required: true } },
    price_with_tax: { type: 'number', rules: { required: true } }
};

export default class ReceivingProduct {
    constructor() {
        this.validator = new Validator();
        // JSON properties
        this._id = null;
        this._receivingId = null;
        this._productId = null;
        this._quantity = null;
        this._price = null;
        this._priceWithTax = null;
    }

    loadToJSON() {
        // get all properties, ignore methods
        var data = JSON.parse(JSON.stringify(this));
        // extract properties that are part of the json
        extractJsonProps(data);
        return objectKeysToSnakeCase(data);
    }

    loadFromJSON(data) {
        if (!isObject(data)) {
            throw new Error('Warehouse receiving product data was not specified properly');
        }
        // Use json properties keys as setter methods for class properties
        for (let i in data) {
            this[i] = data[i];
        }
    }

    /** SETTER METHODS */

    set id(id) {
        this._id = id;
    }

    set receiving_id(receivingId) {
        this._receivingId = receivingId;
    }

    set product_id(productId) {
        this._productId = productId;
    }

    set quantity(quantity) {
        this._quantity = quantity;
    }

    set price(price) {
        this._price = price;
    }

    set price_with_tax(priceWithTax) {
        this._priceWithTax = priceWithTax;
    }

    /** GETTER METHODS */

    get id() {
        return this._id;
    }

    get receiving_id() {
        return this._receivingId;
    }

    get product_id() {
        return this._productId;
    }

    get quantity() {
        return this._quantity;
    }

    get price() {
        return this._price;
    }

    get price_with_tax() {
        return this._priceWithTax;
    }

    validate() {
        if (this.validator.validate(RECEIVING_PRODUCT_SCHEMA, this)) {
            return true;
        }
        return false;
    }

    getValidationErrors() {
        return this.validator.getValidationErrors();
    }
}