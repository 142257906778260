import {
    isObject,
    extractJsonProps,
    objectKeysToSnakeCase
} from '../../helpers/utils';
import { Validator } from '../core';

const PRICE_SCHEMA = {
    id: { type: 'string' },
    product_id: { type: 'string', rules: { required: true } },
    price: { type: 'number', rules: { required: true } },
    purchase_price: { type: 'number', rules: { required: true } },
    currency: { type: 'string', rules: { required: true } },
    tax: { type: 'number', rules: { required: true } }
};

export default class Price {
    constructor() {
        this.validator = new Validator();
        // JSON properties
        this._id = null;
        this._productId = null;
        this._price = null;
        this._purchasePrice = null;
        this._currency = null;
        this._tax = null;
    }

    loadToJSON() {
        // get all properties, ignore methods
        var data = JSON.parse(JSON.stringify(this));
        // remove all private properties
        extractJsonProps(data);
        return objectKeysToSnakeCase(data);
    }

    /**
     * 
     * @param {*} data 
     */
    loadFromJSON(data) {
        if (!isObject(data)) {
            throw new Error('Price data was not specified properly.');
        }
        // Use json property key as setter method for class property
        for (let i in data) {
            this[i] = data[i];
        }
    }

    /* SETTER METHODS */

    set id(id) {
        this._id = id;
    }

    set product_id(productId) {
        this._productId = productId;
    }

    set price(price) {
        this._price = price;
    }

    set purchase_price(purchasePrice) {
        this._purchasePrice = purchasePrice;
    }

    set currency(currency) {
        this._currency = currency;
    }

    set tax(tax) {
        this._tax = tax;
    }

    /* GETTER METHODS */

    get id() {
        return this._id;
    }

    get product_id() {
        return this._productId;
    }

    get price() {
        return this._price;
    }

    get purchase_price() {
        return this._purchasePrice;
    }

    get currency() {
        return this._currency;
    }

    get tax() {
        return this._tax;
    }

    validate() {
        if (this.validator.validate(PRICE_SCHEMA, this)) {
            return true;
        }
        return false;
    }

    getValidationErrors() {
        return this.validator.getValidationErrors();
    }
}