import { APICall, Observable } from '../core';

// Definition of all supported server side events
const SERVER_SIDE_EVENTS = {
    PRODUCT_CHANGE_EVENT: 'productchange',
    CATEGORY_CHANGE_EVENT: 'categorychange'
};

export default class SSE {
    constructor(bc) {
        this.bc = bc;
        this.eventSource = null;
        this.registeredEvents = [];
        this.eventsHandlers = {};
        this.observers = {};
    }

    // Register getter for each of the supported events
    get PRODUCT_CHANGE_EVENT() { return 'PRODUCT_CHANGE_EVENT'; }
    get CATEGORY_CHANGE_EVENT() { return 'CATEGORY_CHANGE_EVENT'; }

    /**
     * Register to sse service and open event source
     */
    register() {
        const request = new APICall(
            this.bc.apiKey,
            null,
            `${this.bc.baseUrl}/v1/sse/register`,
            'GET'
        );
        return request.send()
            .then(resp => {
                this.eventSource = new EventSource(`${this.bc.baseUrl}/v1/sse/${resp.data.ticket}?api_key=${this.bc.apiKey}`);
                return Promise.resolve(true);
            })
            .catch(err => {
                Promise.reject(err);
            });
    }

    /**
     * Subscribe to an event and add observer function.
     * Multiple functions are supported for each event.
     * @param {string} eventName event name
     * @param {function} f observer function to be executed on the specified event
     */
    subscribe(eventName, f) {
        if (!f) {
            // function can't be undefined
            throw new Error('Undefined function provided');
        }
        if (!SERVER_SIDE_EVENTS[eventName]) {
            // undefined event listener
            throw new Error('Undefined event');
        }

        if (!this.registeredEvents.includes(eventName)) {
            let _this = this;
            this.eventsHandlers[eventName] = function (e) {
                _this.handleEvent(eventName, e);
            };
            // event listener not initialized, initialize with addEventListener
            this.eventSource.addEventListener(SERVER_SIDE_EVENTS[eventName], this.eventsHandlers[eventName]);
            this.registeredEvents.push(eventName);
        }

        // push new function to the observers list
        if (!this.observers[eventName]) {
            this.observers[eventName] = new Observable();
        }
        this.observers[eventName].subscribe(f);
    }

    /**
     * Remove observer function for specified event.
     * @param {string} eventName event name
     * @param {function} f observer function to be removed from the specified event
     */
    removeObserver(eventName, f) {
        this.observers[eventName].unsubscribe(f);
    }

    /**
     * Unsubscribe from the specified event.
     * @param {string} eventName event name
     */
    unsubscribe(eventName) {
        this.eventSource.removeEventListener(SERVER_SIDE_EVENTS[eventName], this.eventsHandlers[eventName]);
        this.registeredEvents = this.registeredEvents.filter(en => en !== SERVER_SIDE_EVENTS[eventName]);
        delete this.eventsHandlers[eventName];
        delete this.observers[eventName];
    }

    /**
     * Close event source initialized with the register method
     */
    close() {
        this.eventSource.close();
        this.eventSource = null;
        this.registeredEvents = [];
        this.eventsHandlers = {};
        this.observers = {};
    }

    /**
     * Handler method used to execute observers for specified event.
     * @param {string} eventName event name
     * @param {object} e event data sent from the server
     */
    handleEvent(eventName, e) {
        let data = JSON.parse(e.data);
        this.observers[eventName].notify(data);
    }
}