const JSON_CONTENT_TYPE = 'application/json; charset=UTF-8';
const MULTIPART_CONTENT_TYPE = 'multipart/form-data';

export default class APICall {
    constructor(apiKey, csrf, requestUrl, method, payload) {
        this.apiKey = apiKey;
        this.csrf = csrf;
        this.requestUrl = requestUrl;
        this.method = method;
        this.payload = payload;
        this.headers = new Headers();
        this.request = null;
    }

    setRequestHeaders(contentType) {
        this.headers.append('X-Booster-Api-Key', this.apiKey);
        if (this.csrf) {
            this.headers.append('X-CSRF-Key', this.csrf);
        }
        if (['POST', 'PUT'].indexOf(this.method) != -1) {
            switch (contentType) {
                case JSON_CONTENT_TYPE:
                    this.headers.append('Content-Type', contentType);
                    break;
            }
        }
    }

    initRequest(contentType) {
        let options = {
            method: this.method,
            headers: this.headers,
            credentials: 'include'
        };
        if (this.payload) {
            switch (contentType) {
                case JSON_CONTENT_TYPE:
                    options['body'] = JSON.stringify(this.payload);
                    break;
                case MULTIPART_CONTENT_TYPE:
                    this.setMultipartPayload();
                    options['body'] = this.payload;
                    break;
            }
        }
        this.request = new Request(this.requestUrl, options);
    }

    setMultipartPayload() {
        let formData = new FormData();
        for (let i in this.payload) {
            formData.append(i, this.payload[i]);
        }
        this.payload = formData;
    }

    handleResponse(response) {
        let respBodyPromise = null;
        const contentType = response.headers.get('Content-Type');
        if (contentType && contentType.indexOf('application/json') !== -1) {
            respBodyPromise = response.json();
        } else {
            respBodyPromise = response.text();
        }

        return respBodyPromise
            .then(data => {
                let returnData = {
                    ok: response.ok,
                    status: response.status,
                    statusText: response.statusText,
                    data: data,
                    url: response.url
                };
                if (response && response.ok) {
                    return Promise.resolve(returnData);
                } else {
                    return Promise.reject(returnData);
                }
            });
    }

    sendRequest() {
        return fetch(this.request).then(this.handleResponse);
    }

    send() {
        this.setRequestHeaders(JSON_CONTENT_TYPE);
        this.initRequest(JSON_CONTENT_TYPE);
        return this.sendRequest();
    }

    upload() {
        this.setRequestHeaders(MULTIPART_CONTENT_TYPE);
        this.initRequest(MULTIPART_CONTENT_TYPE);
        return this.sendRequest();
    }
}