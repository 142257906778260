import {
    isObject,
    extractJsonProps,
    objectKeysToSnakeCase
} from '../../helpers/utils';
import { Validator } from '../core';

const PROMOTION_SCHEMA = {
    id: { type: 'string' },
    product_id: { type: 'string', rules: { required: true } },
    available_from: { type: 'date', rules: { required: true } },
    available_to: { type: 'date', rules: { required: true } },
    price: { type: 'number', rules: { required: true } },
    percentage: { type: 'number' }
};

export default class Promotion {
    constructor() {
        this.validator = new Validator();
        // JSON properties
        this._id = null;
        this._productId = null;
        this._availableFrom = null;
        this._availableTo = null;
        this._price = null;
        this._percentage = null;
    }

    loadToJSON() {
        // get all properties, ignore methods
        var data = JSON.parse(JSON.stringify(this));
        // remove all private properties
        extractJsonProps(data);
        return objectKeysToSnakeCase(data);
    }

    /**
     * 
     * @param {*} data 
     */
    loadFromJSON(data) {
        if (!isObject(data)) {
            throw new Error('Promotion data was not specified properly.');
        }
        // Use json property key as setter method for class property
        for (let i in data) {
            this[i] = data[i];
        }
    }

    /* SETTER METHODS */

    set id(id) {
        this._id = id;
    }

    set product_id(productId) {
        this._productId = productId;
    }

    set available_from(availableFrom) {
        this._availableFrom = availableFrom;
    }

    set available_to(availableTo) {
        this._availableTo = availableTo;
    }

    set price(price) {
        this._price = price;
    }

    set percentage(percentage) {
        this._percentage = percentage;
    }

    /* GETTER METHODS */

    get id() {
        return this._id;
    }

    get product_id() {
        return this._productId;
    }

    get available_from() {
        return this._availableFrom;
    }

    get available_to() {
        return this._availableTo;
    }

    get price() {
        return this._price;
    }

    get percentage() {
        return this._percentage;
    }

    validate() {
        if (this.validator.validate(PROMOTION_SCHEMA, this)) {
            return true;
        }
        return false;
    }

    getValidationErrors() {
        return this.validator.getValidationErrors();
    }
}