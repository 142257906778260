import {
    isObject,
    objectKeysToSnakeCase,
    extractJsonProps
} from '../../helpers/utils';
import { Validator } from '../core';

const COMPANY_SCHEMA = {
    id: { type: 'string' },
    name: { type: 'string', rules: { required: true } },
    short_name: { type: 'string', rules: { required: true } },
    country: { type: 'string', rules: { required: true, length: 2 } },
    city: { type: 'string', rules: { required: true } },
    zipcode: { type: 'string', rules: { required: true } },
    address: { type: 'string', rules: { required: true } },
    email: { type: 'string' },
    phone: { type: 'string' },
    tags: { type: 'array', rules: { required: true, itemsType: 'string', minItems: 1 } }
};

export default class Company {

    constructor() {
        this.validator = new Validator();
        // JSON properties
        this._id = null;
        this._name = null;
        this._shortName = null;
        this._country = null;
        this._city = null;
        this._zipcode = null;
        this._address = null;
        this._email = null;
        this._phone = null;
        this._tags = null;
    }

    loadToJSON() {
        // get all properties, ignore methods
        var data = JSON.parse(JSON.stringify(this));
        // remove all private properties
        extractJsonProps(data);
        return objectKeysToSnakeCase(data);
    }

    /**
     * 
     * @param {*} data 
     */
    loadFromJSON(data) {
        if (!isObject(data)) {
            throw new Error('Company data was not specified properly.');
        }
        // Use json property key as setter method for class property
        for (let i in data) {
            this[i] = data[i];
        }
    }

    /* SETTER METHODS */

    set id(id) {
        this._id = id;
    }

    set name(name) {
        this._name = name;
    }

    set short_name(shortName) {
        this._shortName = shortName;
    }

    set country(country) {
        this._country = country;
    }

    set city(city) {
        this._city = city;
    }

    set zipcode(zipcode) {
        this._zipcode = zipcode;
    }

    set address(address) {
        this._address = address;
    }

    set email(email) {
        this._email = email;
    }

    set phone(phone) {
        this._phone = phone;
    }

    set tags(tags) {
        this._tags = tags;
    }

    /* GETTER METHODS */

    get id() {
        return this._id;
    }

    get name() {
        return this._name;
    }

    get short_name() {
        return this._shortName;
    }

    get country() {
        return this._country;
    }

    get city() {
        return this._city;
    }

    get zipcode() {
        return this._zipcode;
    }

    get address() {
        return this._address;
    }

    get email() {
        return this._email;
    }

    get phone() {
        return this._phone;
    }

    get tags() {
        return this._tags;
    }

    validate() {
        if (this.validator.validate(COMPANY_SCHEMA, this)) {
            return true;
        }
        return false;
    }

    getValidationErrors() {
        return this.validator.getValidationErrors();
    }
}