import { APICall, Observable } from '../core';
import {
    MIN_TOKEN_EXPIRATION_TIME,
    RENEW_TOKEN_CHECK_INTERVAL_TIME
} from '../../helpers/constants';
import { isString } from '../../helpers/utils';
import { User } from '../user';
import { AccountRequest } from '../user';

export default class AuthService {
    constructor(bc) {
        this.bc = bc;
        // TODO: give this prop more meaningful name, like 'authStateChangeObserver'
        this.observer = new Observable();
        this.jwtData = null;
        this.isAuthStateResolved = false;
        this.renewTokenInterval = null;

        // trigger get auth status
        this.getAuthStatus()
            .then(status => {
                this.isAuthStateResolved = true;
                this.observer.notify(status);
                this.setRenewTokenInterval();
            })
            .catch();
    }

    /**
     * 
     * @param {*} jwt 
     */
    setJwtData(jwtData) {
        this.jwtData = jwtData;
    }

    /**
     * 
     */
    saveJwtPayloadData(jwt) {
        let jwtData = this.parseJWT(jwt);
        this.setJwtData(jwtData);
        localStorage.setItem('BC_JWT_DATA', JSON.stringify(jwtData));
    }

    /**
     * 
     */
    setRenewTokenInterval() {
        this.renewTokenInterval = setInterval(() => {
            if (!this.jwtData) {
                return;
            }
            let tokenExpStatus = this.checkTokenExpirationTime(this.jwtData);
            if (tokenExpStatus === 2) {
                this.renewToken();
            }
        }, RENEW_TOKEN_CHECK_INTERVAL_TIME);
    }

    /**
     * 
     */
    clearRenewTokenInterval() {
        clearInterval(this.renewTokenInterval);
        this.renewTokenInterval = null;
    }

    /**
     * 
     * @param {*} f 
     */
    onAuthStateChanged(f) {
        if (this.isAuthStateResolved) {
            f(this.jwtData);
        }
        this.observer.subscribe(f);
    }

    /**
     * 
     */
    getAuthStatus() {
        // BC_SIT cookie is set when the user signs in with a provider
        let sit = this.readCookie('BC_SIT');
        if (sit !== '') {
            return this.getTokenBySIT(sit);
        }

        let jwtData = localStorage.getItem('BC_JWT_DATA');
        if (!jwtData) {
            return Promise.resolve(this.jwtData);
        }
        try {
            // try to parse jwtData stored in the local storage
            jwtData = JSON.parse(jwtData);
            let tokenExpStatus = this.checkTokenExpirationTime(jwtData);
            switch (tokenExpStatus) {
                case 0:
                    return Promise.resolve(this.jwtData);
                case 1:
                    this.setJwtData(jwtData);
                    return Promise.resolve(this.jwtData);
                case 2:
                    this.setJwtData(jwtData);
                    return this.renewToken();
            }
        } catch (e) {
            return Promise.resolve(false);
        }
    }

    /**
     * 
     */
    renewToken() {
        const request = new APICall(
            this.bc.apiKey,
            this.jwtData.csrf,
            `${this.bc.baseUrl}/v1/auth/renew`,
            'GET'
        );
        return request.send()
            .then(response => {
                this.saveJwtPayloadData(response.data.jwt);
                if (this.isAuthStateResolved) {
                    this.observer.notify(this.jwtData);
                }
                return this.jwtData;
            })
            .catch(error => Promise.reject(error));
    }

    /**
     * Check token expiration time.
     * 
     * @param {Object} jwt JWT data
     * @return {Integer} Return a number (0, 1 or 2) that represents one of the following statuses:
     * 0 - token is expired
     * 1 - token is valid
     * 2 - token is less then MIN_TOKEN_EXPIRATION_TIME before expiring
     */
    checkTokenExpirationTime(jwt) {
        let currentTime = Math.round(new Date().getTime() / 1000);
        let timeDiff = jwt.exp - currentTime;
        if (timeDiff > MIN_TOKEN_EXPIRATION_TIME) {
            return 1;
        } else if (timeDiff < MIN_TOKEN_EXPIRATION_TIME && timeDiff > 0) {
            return 2;
        } else {
            return 0;
        }
    }

    /**
     * 
     */
    parseJWT(token) {
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    }

    /**
     * 
     * @param {*} cookieName 
     */
    readCookie(cookieName) {
        let cname = `${cookieName}=`;
        let csplit = document.cookie.split(';');
        for (let i in csplit) {
            let item = csplit[i]
            while (item.charAt(0) == ' ') {
                item = item.substring(1);
            }
            if (item.indexOf(cname) == 0) {
                return item.substring(cname.length, item.length);
            }
        }
        return '';
    }

    /**
     * 
     * @param {*} cookieName 
     */
    deleteCookie(cookieName) {
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    }

    /**
     * 
     * @param {*} sit 
     */
    getTokenBySIT(sit) {
        if (sit === '') {
            return Promise.reject({
                status: 0,
                message: 'Sign in token (SIT) is not valid.'
            });
        }
        const request = new APICall(
            this.bc.apiKey,
            null,
            `${this.bc.baseUrl}/v1/auth/token/${sit}`,
            'GET',
        );
        return request.send()
            .then(response => {
                if (response.data && response.data.status === 'ACCOUNT_EXISTS') {
                    return response;
                }
                this.saveJwtPayloadData(response.data.jwt);
                this.deleteCookie('BC_SIT');
                return this.jwtData;
            })
            .catch(error => Promise.reject(error));
    }

    /**
     * 
     * @param {*} sit 
     */
    validateToken() {
        const request = new APICall(
            this.bc.apiKey,
            null,
            `${this.bc.baseUrl}/v1/auth/validate`,
            'GET',
        );
        return request.send()
            .then(response => {
                return response;
            })
            .catch(error => Promise.reject(error));
    }

    /**
     * 
     * @param {*} data 
     */
    createAccount(u) {
        if (!(u instanceof User)) {
            return Promise.reject({
                status: 0,
                message: 'User data argument must be an instance of the User class.'
            });
        }
        if (!u.validate()) {
            return Promise.reject({
                status: 0,
                message: 'Invalid user data.',
                errors: u.getValidationErrors()
            });
        }
        let payload = u.loadToJSON();
        const request = new APICall(
            this.bc.apiKey,
            null,
            `${this.bc.baseUrl}/v1/auth/create-account`,
            'POST',
            payload
        );
        return request.send();
    }

    /**
     * 
     * @param {*} token 
     */
    confirmAccount(token) {
        if (!isString(token) || token === '') {
            return Promise.reject({
                status: 0,
                message: 'Invalid confirmation token.'
            });
        }
        let payload = {
            confirmation_token: token
        };
        const request = new APICall(
            this.bc.apiKey,
            null,
            `${this.bc.baseUrl}/v1/auth/confirm-account`,
            'POST',
            payload
        );
        return request.send();
    }

    /**
     * 
     * @param {*} email 
     * @param {*} password 
     */
    login(email, password) {
        if (!isString(email) || email === '') {
            return Promise.reject({
                status: 0,
                message: 'Email is not valid or it was not specified properly.'
            });
        }
        if (!isString(password) || password === '') {
            return Promise.reject({
                status: 0,
                message: 'Password is not valid or it was not specified properly.'
            });
        }
        let payload = {
            email: email,
            password: password
        };
        const request = new APICall(
            this.bc.apiKey,
            null,
            `${this.bc.baseUrl}/v1/auth/login`,
            'POST',
            payload
        );
        return request.send()
            .then(response => {
                this.saveJwtPayloadData(response.data.jwt);
                this.observer.notify(this.jwtData);
                return response;
            })
            .catch(error => Promise.reject(error));
    }

    /**
     * 
     * @param {*} email 
     */
    resetPasswordLink(email) {
        if (!isString(email) || email === '') {
            return Promise.reject({
                status: 0,
                message: 'Email is not valid or it was not specified properly.'
            });
        }
        let payload = { email: email };
        const request = new APICall(
            this.bc.apiKey,
            null,
            `${this.bc.baseUrl}/v1/auth/reset-password-link`,
            'POST',
            payload
        );
        return request.send();
    }

    /**
     * 
     * @param {*} resetHash 
     * @param {*} password 
     */
    resetPassword(resetHash, password) {
        if (!isString(resetHash) || resetHash === '') {
            return Promise.reject({
                status: 0,
                message: 'Reset hash is not valid or it was not specified properly.'
            });
        }
        if (!isString(password) || password === '') {
            return Promise.reject({
                status: 0,
                message: 'Password is not valid or it was not specified properly.'
            });
        }
        let payload = {
            reset_hash: resetHash,
            password: password
        };
        const request = new APICall(
            this.bc.apiKey,
            null,
            `${this.bc.baseUrl}/v1/auth/reset-password`,
            'POST',
            payload
        );
        return request.send();
    }

    /**
     * Logout user.
     */
    logout() {
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/auth/logout`,
            'POST'
        );
        return request.send()
            .then(response => {
                localStorage.removeItem('BC_JWT_DATA');
                this.deleteCookie('BC_JWT');
                this.deleteCookie('BC_VID');
                this.setJwtData(null);
                this.observer.notify(this.jwtData);
                return response;
            })
            .catch(error => {
                return error;
            });
    }

    /**
     * 
     */
    adminLogin(email, password) {
        if (!isString(email) || email === '') {
            return Promise.reject({
                status: 0,
                message: 'Email is not valid or it was not specified properly.'
            });
        }
        if (!isString(password) || password === '') {
            return Promise.reject({
                status: 0,
                message: 'Password is not valid or it was not specified properly.'
            });
        }
        let payload = {
            email: email,
            password: password
        };
        const request = new APICall(
            this.bc.apiKey,
            null,
            `${this.bc.baseUrl}/v1/auth/admin/login`,
            'POST',
            payload
        );
        return request.send()
            .then(response => {
                this.saveJwtPayloadData(response.data.jwt);
                this.observer.notify(this.jwtData);
                return response;
            })
            .catch(error => Promise.reject(error));
    }

    /**
     * Logout admin user.
     */
    adminLogout() {
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/auth/admin/logout`,
            'POST'
        );
        return request.send()
            .then(response => {
                localStorage.removeItem('BC_JWT_DATA');
                this.deleteCookie('BC_JWT');
                this.setJwtData(null);
                this.observer.notify(this.jwtData);
                return response;
            })
            .catch(error => {
                return error;
            });
    }

    createVisitorId() {
        const request = new APICall(
            this.bc.apiKey,
            null,
            `${this.bc.baseUrl}/v1/auth/visitorid`,
            'GET',
        );
        return request.send();
    }

    /**
     * Create user account by requesting access
     * 
     * @param {object} ac Instance of the AccountRequest class
     * @returns {promise} Returns a Promise that, when fulfilled, will either return OK status 
     * or an Error with the problem.
     */
    requestAccess(ac) {
        if (!(ac instanceof AccountRequest)) {
            return Promise.reject({
                status: 0,
                message: 'Account Request data argument must be an instance of the AccountRequest class.'
            });
        }
        if (!ac.validate()) {
            return Promise.reject({
                status: 0,
                message: 'Invalid account request data.',
                errors: ac.getValidationErrors()
            });
        }
        let payload = ac.loadToJSON();
        const request = new APICall(
            this.bc.apiKey,
            null,
            `${this.bc.baseUrl}/v1/auth/require-access`,
            'POST',
            payload
        );
        return request.send();
    }

    getAllAccessApplications() {
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/auth/require-access`,
            'GET',
        );
        return request.send();
    }

    /**
     * Update account request data
     * 
     * @param {string} id Account request id 
     * @param {object} ac Instance of the AccountRequest class
     * @returns {promise} Returns a Promise that, when fulfilled, will either return OK status 
     * or an Error with the problem.
     */
    updateAccessApplication(id, ac) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Account request id was not specified properly.'
            });
        }
        if (!(ac instanceof AccountRequest)) {
            return Promise.reject({
                status: 0,
                message: 'Account Request data argument must be an instance of the AccountRequest class.'
            });
        }
        if (!ac.validate()) {
            return Promise.reject({
                status: 0,
                message: 'Invalid account request data.',
                errors: ac.getValidationErrors()
            });
        }
        let payload = ac.loadToJSON();
        const request = new APICall(
            this.bc.apiKey,
            null,
            `${this.bc.baseUrl}/v1/auth/require-access/${id}`,
            'PUT',
            payload
        );
        return request.send();
    }
}