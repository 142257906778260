import Product from '../Product';
import {
    isObject,
    isNullOrUndefined
} from '../../../helpers/utils';
import { Validator } from '../../core';
import {
    ProductExpiration,
    ProductMeasurement
} from './Common';

const PERSONAL_HYGIENE_META_SCHEMA = {
    personal_hygiene_expiration: { type: 'classInstance', rules: { required: true } },
    personal_hygiene_allergens: { type: 'array', rules: { itemsType: 'string' } },
    personal_hygiene_packaging: { type: 'string', rules: { required: true } },
    personal_hygiene_ingredients: { type: 'array', rules: { itemsType: 'string' } },
    personal_hygiene_measurement: { type: 'classInstance' },
    personal_hygiene_gross_weight_measurement: { type: 'classInstance' },
};

/**
 * Describes personal hygiene product meta properties.
 * Used with 'personal_hygiene' product type.
 * 
 * @class
 */
export default class PersonalHygiene extends Product {

    constructor() {
        super();
        // JSON properties
        this._meta = null;
    }

    /* SETTER METHODS */

    set meta(meta) {
        if (isNullOrUndefined(meta) || !isObject(meta)) {
            return;
        }
        this._meta = new PersonalHygieneMeta();
        this._meta.loadFromJSON(meta);
    }

    /* GETTER METHODS */

    get meta() {
        return this._meta;
    }
}

class PersonalHygieneMeta {
    constructor() {
        this.validator = new Validator();
        // JSON properties
        this._personalHygieneAllergens = null;
        this._personalHygieneExpiration = null;
        this._personalHygieneIngredients = null;
        this._personalHygieneMeasurement = null;
        this._personalHygienePackaging = null;
        this._personalHygieneGrossWeightMeasurement = null;
    }

    loadFromJSON(data) {
        if (!isObject(data)) {
            return;
        }
        // Use json property key as setter method for class property
        for (let i in data) {
            this[i] = data[i];
        }
    }

    /* SETTER METHODS */

    set personal_hygiene_allergens(personalHygieneAllergens) {
        this._personalHygieneAllergens = personalHygieneAllergens;
    }

    set personal_hygiene_expiration(personalHygieneExpiration) {
        if (isNullOrUndefined(personalHygieneExpiration) || !isObject(personalHygieneExpiration)) {
            return;
        }
        this._personalHygieneExpiration = new ProductExpiration();
        this._personalHygieneExpiration.loadFromJSON(personalHygieneExpiration);
    }

    set personal_hygiene_ingredients(personalHygieneIngredients) {
        this._personalHygieneIngredients = personalHygieneIngredients;
    }

    set personal_hygiene_measurement(personalHygieneMeasurement) {
        if (isNullOrUndefined(personalHygieneMeasurement) || !isObject(personalHygieneMeasurement)) {
            return;
        }
        this._personalHygieneMeasurement = new ProductMeasurement();
        this._personalHygieneMeasurement.loadFromJSON(personalHygieneMeasurement);
    }

    set personal_hygiene_packaging(personalHygienePackaging) {
        this._personalHygienePackaging = personalHygienePackaging;
    }

    set personal_hygiene_gross_weight_measurement(personalHygieneGrossWeightMeasurement) {
        if (isNullOrUndefined(personalHygieneGrossWeightMeasurement) || !isObject(personalHygieneGrossWeightMeasurement)) {
            return;
        }
        this._personalHygieneGrossWeightMeasurement = new ProductMeasurement();
        this._personalHygieneGrossWeightMeasurement.loadFromJSON(personalHygieneGrossWeightMeasurement);
    }

    /* GETTER METHODS */

    get personal_hygiene_allergens() {
        return this._personalHygieneAllergens;
    }

    get personal_hygiene_expiration() {
        return this._personalHygieneExpiration;
    }

    get personal_hygiene_ingredients() {
        return this._personalHygieneIngredients;
    }

    get personal_hygiene_measurement() {
        return this._personalHygieneMeasurement;
    }

    get personal_hygiene_packaging() {
        return this._personalHygienePackaging;
    }

    get personal_hygiene_gross_weight_measurement() {
        return this._personalHygieneGrossWeightMeasurement;
    }

    validate() {
        return this.validator.validate(PERSONAL_HYGIENE_META_SCHEMA, this);
    }

    getValidationErrors() {
        return this.validator.getValidationErrors();
    }
}