import {
    isObject,
    extractJsonProps,
    objectKeysToSnakeCase
} from '../../helpers/utils';
import { Validator } from '../core';

const PREFERENCES_SCHEMA = {
    id: { type: 'string' },
    user_id: { type: 'string', rules: { required: true } },
    email_newsletter: { type: 'boolean', rules: { required: true } },
    email_order_invoice: { type: 'boolean', rules: { required: true } },
    email_special_offers: { type: 'boolean', rules: { required: true } },
    email_monthly_stats: { type: 'boolean', rules: { required: true } }
};

export default class Preferences {
    constructor() {
        this.validator = new Validator();
        // JSON properties
        this._id = null;
        this._userId = null;
        this._emailNewsletter = null;
        this._emailOrderInvoice = null;
        this._emailSpecialOffers = null;
        this._emailMonthlyStats = null;
    }

    loadToJSON() {
        // get all properties, ignore methods
        var data = JSON.parse(JSON.stringify(this));
        // extract properties that are part of the json
        extractJsonProps(data);
        return objectKeysToSnakeCase(data);
    }

    loadFromJSON(data) {
        if (!isObject(data)) {
            throw new Error('Warehouse receiving data was not specified properly.');
        }
        // Use json property key as setter method for class property
        for (let i in data) {
            this[i] = data[i];
        }
    }

    /* SETTER METHODS */

    set id(id) {
        this._id = id;
    }

    set user_id(userId) {
        this._userId = userId;
    }

    set email_newsletter(emailNewsletter) {
        this._emailNewsletter = emailNewsletter;
    }

    set email_order_invoice(emailOrderInvoice) {
        this._emailOrderInvoice = emailOrderInvoice;
    }

    set email_special_offers(emailSpecialOffers) {
        this._emailSpecialOffers = emailSpecialOffers;
    }

    set email_monthly_stats(emailMonthlyStats) {
        this._emailMonthlyStats = emailMonthlyStats;
    }

    /* GETTER METHODS */

    get id() {
        return this._id;
    }

    get user_id() {
        return this._userId;
    }

    get email_newsletter() {
        return this._emailNewsletter;
    }

    get email_order_invoice() {
        return this._emailOrderInvoice;
    }

    get email_special_offers() {
        return this._emailSpecialOffers;
    }

    get email_monthly_stats() {
        return this._emailMonthlyStats;
    }

    validate() {
        if (this.validator.validate(PREFERENCES_SCHEMA, this)) {
            return true;
        }
        return false;
    }

    getValidationErrors() {
        return this.validator.getValidationErrors();
    }
}