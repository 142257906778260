import {
    isObject,
    extractJsonProps,
    objectKeysToSnakeCase
} from '../../helpers/utils';
import { Validator } from '../core';

const PAGE_SCHEMA = {
    id: { type: 'string' },
    title: { type: 'string', rules: { required: true } },
    content: { type: 'string', rules: { required: true } },
    permalink: { type: 'string' },
    tags: { type: 'array', rules: { itemsType: 'string' } },
    image: { type: 'string' }
};

export default class Page {

    constructor() {
        this.validator = new Validator();
        // JSON properties
        this._id = null;
        this._title = null;
        this._content = null;
        this._permalink = null;
        this._tags = null;
        this._image = null;
    }

    loadToJSON() {
        // get all properties, ignore methods
        var data = JSON.parse(JSON.stringify(this));
        // remove all private properties
        extractJsonProps(data);
        return objectKeysToSnakeCase(data);
    }

    /**
     * 
     * @param {*} data 
     */
    loadFromJSON(data) {
        if (!isObject(data)) {
            throw new Error('Page data was not specified properly.');
        }
        // Use json property key as setter method for class property
        for (let i in data) {
            this[i] = data[i];
        }
    }

    /* SETTER METHODS */

    set id(id) {
        this._id = id;
    }

    set title(title) {
        this._title = title;
    }

    set content(content) {
        this._content = content;
    }

    set permalink(permalink) {
        this._permalink = permalink;
    }

    set tags(tags) {
        this._tags = tags;
    }

    set image(image) {
        this._image = image;
    }

    /* GETTER METHODS */

    get id() {
        return this._id;
    }

    get title() {
        return this._title;
    }

    get content() {
        return this._content;
    }

    get permalink() {
        return this._permalink;
    }

    get tags() {
        return this._tags;
    }

    get image() {
        return this._image;
    }

    validate() {
        if (this.validator.validate(PAGE_SCHEMA, this)) {
            return true;
        }
        return false;
    }

    getValidationErrors() {
        return this.validator.getValidationErrors();
    }
}