import {
    isObject,
    isArray,
    objectKeysToSnakeCase,
    extractJsonProps,
    isNullOrUndefined
} from '../../helpers/utils';
import ReceivingProduct from './ReceivingProduct';
import { Validator } from '../core';

const RECEIVING_SCHEMA = {
    id: { type: 'string' },
    iiid: { type: 'string' },
    vendor_id: { type: 'string', rules: { required: true } },
    status: { type: 'string' },
    approved_by: { type: 'string' },
    received_by: { type: 'string' },
    documents: { type: 'array', rules: { itemsType: 'string' } },
    products: { type: 'array', rules: { required: true, itemsType: 'classInstance', minItems: 1 } }
};

export default class Receiving {

    constructor() {
        this.validator = new Validator();
        // JSON properties
        this._id = null;
        this._iiid = null;
        this._vendorId = null;
        this._status = null;
        this._approvedBy = null;
        this._receivedBy = null;
        this._documents = null;
        this._products = null;
    }

    loadToJSON() {
        // get all properties, ignore methods
        var data = JSON.parse(JSON.stringify(this));
        // extract properties that are part of the json
        extractJsonProps(data);
        return objectKeysToSnakeCase(data);
    }

    loadFromJSON(data) {
        if (!isObject(data)) {
            throw new Error('Warehouse receiving data was not specified properly.');
        }
        // Use json property key as setter method for class property
        for (let i in data) {
            this[i] = data[i];
        }
    }

    /* SETTER METHODS */

    set id(id) {
        this._id = id;
	}
	
    set iiid(iiid) {
        this._iiid = iiid;
    }

    set vendor_id(vendorId) {
        this._vendorId = vendorId;
    }

    set status(status) {
        this._status = status;
    }

    set received_by(receivedBy) {
        this._receivedBy = receivedBy;
    }

    set approved_by(approvedBy) {
        this._approvedBy = approvedBy;
    }

    set documents(documents) {
        this._documents = documents;
    }

    set products(products) {
        if (isNullOrUndefined(products)) {
            return;
        }
        if (!isArray(products)) {
            return;
        }
        this._products = [];
        for (let i in products) {
            let p = new ReceivingProduct();
            p.loadFromJSON(products[i]);
            this._products.push(p);
        }
    }

    /* GETTER METHODS */

    get id() {
        return this._id;
	}
	
    get iiid() {
        return this._iiid;
    }

    get vendor_id() {
        return this._vendorId;
    }

    get status() {
        return this._status;
    }

    get received_by() {
        return this._receivedBy;
    }

    get approved_by() {
        return this._approvedBy;
    }

    get documents() {
        return this._documents;
    }

    get products() {
        return this._products;
    }

    validate() {
        if (this.validator.validate(RECEIVING_SCHEMA, this)) {
            return true;
        }
        return false;
    }

    getValidationErrors() {
        return this.validator.getValidationErrors();
    }
}