import {
    isObject,
    extractJsonProps,
    objectKeysToSnakeCase
} from '../../helpers/utils';
import { Validator } from '../core';

const CATEGORY_SCHEMA = {
    id: { type: 'string' },
    parent_id: { type: 'string' },
    name: { type: 'string', rules: { required: true } },
    permalink: { type: 'string' },
    order: { type: 'integer', rules: { required: true } }
};

export default class Category {

    constructor() {
        this.validator = new Validator();
        // JSON properties
        this._id = null;
        this._parentId = null;
        this._name = null;
        this._permalink = null;
        this._order = null;
    }

    loadToJSON() {
        // get all properties, ignore methods
        var data = JSON.parse(JSON.stringify(this));
        // remove all private properties
        extractJsonProps(data);
        return objectKeysToSnakeCase(data);
    }

    /**
     * 
     * @param {*} data 
     */
    loadFromJSON(data) {
        if (!isObject(data)) {
            throw new Error('Category data was not specified properly.');
        }
        // Use json property key as setter method for class property
        for (let i in data) {
            this[i] = data[i];
        }
    }

    /* SETTER METHODS */

    set id(id) {
        this._id = id;
    }

    set parent_id(parentId) {
        this._parentId = parentId;
    }

    set name(name) {
        this._name = name;
    }

    set permalink(permalink) {
        this._permalink = permalink;
    }

    set order(order) {
        this._order = order;
    }

    /* GETTER METHODS */

    get id() {
        return this._id;
    }

    get parent_id() {
        return this._parentId;
    }

    get name() {
        return this._name;
    }

    get permalink() {
        return this._permalink;
    }

    get order() {
        return this._order;
    }

    validate() {
        if (this.validator.validate(CATEGORY_SCHEMA, this)) {
            return true;
        }
        return false;
    }

    getValidationErrors() {
        return this.validator.getValidationErrors();
    }
}