import { APICall } from '../core';

class WebPushSubscriber {
    constructor(bc) {
        this.bc = bc;
        this.swReg = null;
        this.userVisibleOnly = null;
        this.appServerKey = null;
    }

    /**
     * Check if serviceWorker and PushManager APIs are available
     * @param {ServiceWorkerRegistration object} swReg reference to the service worker registration
     */
    setServiceWorker(swReg) {
        if ('serviceWorker' in navigator && 'PushManager' in window) {
            this.swReg = swReg;
        } else {
            throw 'Push messaging is not supported';
        }
    }

    /**
     * Set PushManager options
     * @param {boolean} userVisibleOnly A boolean indicating that the returned push subscription will only be used for messages whose effect is made visible to the user
     * @param {string} appServerKey A Base64-encoded DOMString or ArrayBuffer containing an ECDSA P-256 public key that the push server will use to authenticate the application server
     */
    setSubscribeOptions(userVisibleOnly, appServerKey) {
        this.userVisibleOnly = userVisibleOnly;
        this.appServerKey = appServerKey;
    }

    /**
     * Register for push notifications
     * @param {ServiceWorkerRegistration object} swReg reference to the service worker registration
     * @param {boolean} userVisibleOnly A boolean indicating that the returned push subscription will only be used for messages whose effect is made visible to the user
     * @param {string} appServerKey A Base64-encoded DOMString or ArrayBuffer containing an ECDSA P-256 public key that the push server will use to authenticate the application server 
     */
    register(swReg, userVisibleOnly, appServerKey) {
        try {
            this.setServiceWorker(swReg);
            this.setSubscribeOptions(userVisibleOnly, appServerKey);
            return this.swReg.pushManager.getSubscription()
                .then(subscription => {
                    if (subscription) return Promise.resolve(true); // User is already subscribed, do nothing
                    return this.swReg.pushManager.subscribe({
                        userVisibleOnly: this.userVisibleOnly,
                        applicationServerKey: this.appServerKey
                    })
                        .then(pushSubscription => {
                            const request = new APICall(
                                this.bc.apiKey,
                                this.bc.auth.jwtData.csrf,
                                `${this.bc.baseUrl}/v1/pushsubscriber/subscription/web`,
                                'POST',
                                pushSubscription
                            );
                            return request.send();
                        })
                        .catch(err => {
                            this.swReg.pushManager.getSubscription().then(subscription => subscription.unsubscribe());
                            return Promise.reject(err);
                        });
                })
                .catch(err => Promise.reject(err));
        } catch (error) {
            return Promise.reject(error);
        }
    }

    /**
     * Unsubscribe from PushManager service
     * 
     * TODO: Remove webpush subscription from the back-end (unregister)
     */
    unsubscribe() {
        try {
            return this.swReg.pushManager.getSubscription()
                .then(subscription => subscription.unsubscribe())
                .catch(err => Promise.reject(err));
        } catch (error) {
            return Promise.reject(error);
        }
    }

    /**
     * Check if client is already subscribed to PushManager service
     * @returns 
     */
    isSubscribed() {
        try {
            return this.swReg.pushManager.getSubscription()
                .then(subscription => {
                    if (subscription) return Promise.resolve(true);
                    return Promise.resolve(false);
                })
                .catch(err => Promise.reject(err));
        } catch (error) {
            return Promise.reject(error);
        }
    }
}

export default WebPushSubscriber;