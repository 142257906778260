import {
    isObject,
    extractJsonProps,
    objectKeysToSnakeCase
} from '../../helpers/utils';
import { Validator } from '../core';

const LOCATION_SCHEMA = {
    id: { type: 'string' },
    account_id: { type: 'string' },
    label: { type: 'string' },
    continent: { type: 'string', rules: { required: true } },
    country: { type: 'string', rules: { required: true, length: 2 } },
    state: { type: 'string' },
    zipcode: { type: 'string', rules: { required: true } },
    city: { type: 'string', rules: { required: true } },
    region: { type: 'string' },
    address: { type: 'string' },
    address_number: { type: 'string' },
    longitude: { type: 'number' },
    latitude: { type: 'number' }
};

export default class Location {
    constructor() {
        this.validator = new Validator();
        // JSON properties
        this._id = null;
        this._accountId = null;
        this._label = null;
        this._continent = null;
        this._country = null;
        this._state = null;
        this._zipcode = null;
        this._city = null;
        this._region = null;
        this._address = null;
        this._addressNumber = null;
        this._longitude = null;
        this._latitude = null;
    }

    loadToJSON() {
        // get all properties, ignore methods
        var data = JSON.parse(JSON.stringify(this));
        // extract properties that are part of the json
        extractJsonProps(data);
        return objectKeysToSnakeCase(data);
    }

    loadFromJSON(data) {
        if (!isObject(data)) {
            throw new Error('Warehouse receiving data was not specified properly.');
        }
        // Use json property key as setter method for class property
        for (let i in data) {
            this[i] = data[i];
        }
    }

    /* SETTER METHODS */

    set id(id) {
        this._id = id;
    }

    set account_id(accountId) {
        this._accountId = accountId;
    }

    set label(label) {
        this._label = label;
    }

    set continent(continent) {
        this._continent = continent;
    }

    set country(country) {
        this._country = country;
    }

    set state(state) {
        this._state = state;
    }

    set zipcode(zipcode) {
        this._zipcode = zipcode;
    }

    set city(city) {
        this._city = city;
    }

    set region(region) {
        this._region = region;
    }

    set address(address) {
        this._address = address;
    }

    set address_number(addressNumber) {
        this._addressNumber = addressNumber;
    }

    set longitude(longitude) {
        this._longitude = longitude;
    }

    set latitude(latitude) {
        this._latitude = latitude;
    }

    /* GETTER METHODS */

    get id() {
        return this._id;
    }

    get account_id() {
        return this._accountId;
    }

    get label() {
        return this._label;
    }

    get continent() {
        return this._continent;
    }

    get country() {
        return this._country;
    }

    get state() {
        return this._state;
    }

    get zipcode() {
        return this._zipcode;
    }

    get city() {
        return this._city;
    }

    get region() {
        return this._region;
    }

    get address() {
        return this._address;
    }

    get address_number() {
        return this._addressNumber;
    }

    get longitude() {
        return this._longitude;
    }

    get latitude() {
        return this._latitude;
    }

    validate() {
        if (this.validator.validate(LOCATION_SCHEMA, this)) {
            return true;
        }
        return false;
    }

    getValidationErrors() {
        return this.validator.getValidationErrors();
    }
}