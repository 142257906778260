import {
    isObject,
    extractJsonProps,
    objectKeysToSnakeCase
} from '../../helpers/utils';
import { Validator } from '../core';

const ORDER_SCHEMA = {
    id: { type: 'string' },
    full_name: { type: 'string', rules: { required: true } },
    email: { type: 'string', rules: { required: true } },
    phone: { type: 'string', rules: { required: true } },
    delivery_address: { type: 'string', rules: { required: true } },
    delivery_address_number: { type: 'string', rules: { required: true } },
    delivery_city: { type: 'string', rules: { required: true } },
    delivery_country: { type: 'string', rules: { required: true, length: 2 } },
    delivery_zipcode: { type: 'string', rules: { required: true } },
    delivery_region: { type: 'string', rules: { required: true } },
    delivery_date: { type: 'date', rules: { required: false } },
    delivery_time: { type: 'date'},
    delivery_timeslot: { type: 'string'},
    delivery_latitude: { type: 'number', rules: { required: true } },
    delivery_longitude: { type: 'number', rules: { required: true } },
    delivery_additional_info: { type: 'string', rules: { required: false } },

    recipient_full_name: { type: 'string', rules: { required: false } },
    recipient_phone: { type: 'string', rules: { required: false } },
    recipient_email: { type: 'string', rules: { required: false } },

    delivery_building_entry: { type: 'string', rules: { required: false } },
    delivery_apartment: { type: 'string', rules: { required: false } },
    delivery_building_floor: { type: 'string', rules: { required: false } }
};

export default class Order {

    constructor() {
        this.validator = new Validator();
        // JSON properties
        this._id = null;
        this._fullName = null;
        this._email = null;
        this._phone = null;
        this._deliveryAddress = null;
        this._deliveryAddressNumber = null;
        this._deliveryCity = null;
        this._deliveryCountry = null;
        this._deliveryZipcode = null;
        this._deliveryRegion = null;
        this._deliveryDate = null;
        this._deliveryLatitude = null;
        this._deliveryLongitude = null;
        this._deliveryTime = null;
        this._deliveryTimeslot = null;
        this._deliveryAdditionalInfo = null;

        this._recipientFullName = null;
        this._recipientPhone = null;
        this._recipientEmail = null;

        this._deliveryBuildingEntry = null;
        this._deliveryApartment = null;
        this._deliveryBuildingFloor = null;
    }

    loadToJSON() {
        // get all properties, ignore methods
        var data = JSON.parse(JSON.stringify(this));
        // remove all private properties
        extractJsonProps(data);
        return objectKeysToSnakeCase(data);
    }

    /**
     * 
     * @param {*} data 
     */
    loadFromJSON(data) {
        if (!isObject(data)) {
            throw new Error('Order data was not specified properly.');
        }
        // Use json property key as setter method for class property
        for (let i in data) {
            this[i] = data[i];
        }
    }

    /* SETTER METHODS */

    set id(id) {
        this._id = id;
    }

    set full_name(fullName) {
        this._fullName = fullName;
    }

    set email(email) {
        this._email = email;
    }

    set phone(phone) {
        this._phone = phone;
    }

    set delivery_address(deliveryAddress) {
        this._deliveryAddress = deliveryAddress;
    }

    set delivery_address_number(deliveryAddressNumber) {
        this._deliveryAddressNumber = deliveryAddressNumber;
    }

    set delivery_city(deliveryCity) {
        this._deliveryCity = deliveryCity;
    }

    set delivery_country(deliveryCountry) {
        this._deliveryCountry = deliveryCountry;
    }

    set delivery_zipcode(deliveryZipcode) {
        this._deliveryZipcode = deliveryZipcode;
    }

    set delivery_region(deliveryRegion) {
        this._deliveryRegion = deliveryRegion;
    }

    set delivery_date(deliveryDate) {
        this._deliveryDate = deliveryDate;
    }

    set delivery_time(deliveryTime) {
        this._deliveryTime = deliveryTime;
    }

    set delivery_timeslot(deliveryTimeslot) {
        this._deliveryTimeslot = deliveryTimeslot;
    }

    set delivery_latitude(deliveryLatitude) {
        this._deliveryLatitude = deliveryLatitude;
    }

    set delivery_longitude(deliveryLongitude) {
        this._deliveryLongitude = deliveryLongitude;
	}
	
    set delivery_additional_info(deliveryAdditionalInfo) {
        this._deliveryAdditionalInfo = deliveryAdditionalInfo;
    }

    set recipient_full_name(recipientFullName) {
        this._recipientFullName = recipientFullName;
    }

    set recipient_phone(recipientPhone) {
        this._recipientPhone = recipientPhone;
    }

    set recipient_email(recipientEmail) {
        this._recipientEmail = recipientEmail;
    }

    set delivery_building_entry(deliveryBuildingEntry) {
        this._deliveryBuildingEntry = deliveryBuildingEntry;
    }

    set delivery_apartment(deliveryApartment) {
        this._deliveryApartment = deliveryApartment;
    }

    set delivery_building_floor(deliveryBuildingFloor) {
        this._deliveryBuildingFloor = deliveryBuildingFloor;
    }

    /* GETTER METHODS */

    get id() {
        return this._id;
    }

    get full_name() {
        return this._fullName;
    }

    get email() {
        return this._email;
    }

    get phone() {
        return this._phone;
    }

    get delivery_address() {
        return this._deliveryAddress;
    }

    get delivery_address_number() {
        return this._deliveryAddressNumber;
    }

    get delivery_city() {
        return this._deliveryCity;
    }

    get delivery_country() {
        return this._deliveryCountry;
    }

    get delivery_zipcode() {
        return this._deliveryZipcode;
    }

    get delivery_region() {
        return this._deliveryRegion;
    }

    get delivery_date() {
        return this._deliveryDate;
    }

    get delivery_time() {
        return this._deliveryTime;
    }

    get delivery_timeslot() {
        return this._deliveryTimeslot;
    }

    get delivery_latitude() {
        return this._deliveryLatitude;
    }

    get delivery_longitude() {
        return this._deliveryLongitude;
	}
	
    get delivery_additional_info() {
        return this._deliveryAdditionalInfo;
    }

    get recipient_full_name() {
        return this.recipientFullName;
    }

    get recipient_phone() {
        return this.recipientPhone;
    }

    get recipient_email() {
        return this.recipientEmail;
    }

    get delivery_building_entry() {
        return this._deliveryBuildingEntry;
    }

    get delivery_apartment() {
        return this._deliveryApartment;
    }

    get delivery_building_floor() {
        return this._deliveryBuildingFloor;
    }

    validate() {
        if (this.validator.validate(ORDER_SCHEMA, this)) {
            return true;
        }
        return false;
    }

    getValidationErrors() {
        return this.validator.getValidationErrors();
    }
}