import { APICall } from '../core';

export default class ListsService {

    constructor(bc) {
        this.bc = bc;
    }

    getMeasurements() {
        const request = new APICall(
            this.bc.apiKey,
            null,
            `${this.bc.baseUrl}/v1/lists/measurements`,
            'GET'
        );
        return request.send();
    }

    getTimeMeasurements() {
        const request = new APICall(
            this.bc.apiKey,
            null,
            `${this.bc.baseUrl}/v1/lists/measurements/time`,
            'GET'
        );
        return request.send();
    }

    getCurrencies() {
        const request = new APICall(
            this.bc.apiKey,
            null,
            `${this.bc.baseUrl}/v1/lists/currencies`,
            'GET'
        );
        return request.send();
    }

    getCountries() {
        const request = new APICall(
            this.bc.apiKey,
            null,
            `${this.bc.baseUrl}/v1/lists/countries`,
            'GET'
        );
        return request.send();
    }

    getProductTypes() {
        const request = new APICall(
            this.bc.apiKey,
            null,
            `${this.bc.baseUrl}/v1/lists/product/types`,
            'GET'
        );
        return request.send();
    }

    getProductBadges() {
        const request = new APICall(
            this.bc.apiKey,
            null,
            `${this.bc.baseUrl}/v1/lists/product/badges`,
            'GET'
        );
        return request.send();
    }

    getProductAlergens() {
        const request = new APICall(
            this.bc.apiKey,
            null,
            `${this.bc.baseUrl}/v1/lists/product/alergens`,
            'GET'
        );
        return request.send();
    }

    getProductIngredients() {
        const request = new APICall(
            this.bc.apiKey,
            null,
            `${this.bc.baseUrl}/v1/lists/product/ingredients`,
            'GET'
        );
        return request.send();
    }

    getProductNutriTypes() {
        const request = new APICall(
            this.bc.apiKey,
            null,
            `${this.bc.baseUrl}/v1/lists/product/nutrivals`,
            'GET'
        );
        return request.send();
    }

    getProductPackaging() {
        const request = new APICall(
            this.bc.apiKey,
            null,
            `${this.bc.baseUrl}/v1/lists/product/packaging`,
            'GET'
        );
        return request.send();
    }
}