import {
    isObject,
    objectKeysToSnakeCase,
    extractJsonProps
} from '../../helpers/utils';
import { Validator } from '../core';

const DELIVERY_TIMESLOT_SCHEMA = {
    id: { type: 'string' },
    start_time: { type: 'date', rules: { required: true } },
    end_time: { type: 'date', rules: { required: true } },
    cap: { type: 'number', rules: { required: true } }
};

export default class DeliveryTimeslot {

    constructor() {
        this.validator = new Validator();
        /* JSON properties */
        this._id = null;
        this._startTime = null;
        this._endTime = null;
        this._cap = null;
    }

    loadToJSON() {
        // get all properties, ignore methods
        var data = JSON.parse(JSON.stringify(this));
        // extract properties that are part of the json
        extractJsonProps(data);
        return objectKeysToSnakeCase(data);
    }

    loadFromJSON(data) {
        if (!isObject(data)) {
            throw new Error('Timeslot data was not specified properly.');
        }
        // Use json properties keys as setter methods for class properties
        for (let i in data) {
            this[i] = data[i];
        }
    }

    /* SETTER METHODS */

    set id(id) {
        this._id = id;
    }

    set start_time(startTime) {
        this._startTime = startTime;
    }

    set end_time(endTime) {
        this._endTime = endTime;
    }

    set cap(cap) {
        this._cap = cap;
    }

    /* GETTER METHODS */

    get id() {
        return this._id;
    }

    get start_time() {
        return this._startTime;
    }

    get end_time() {
        return this._endTime;
    }

    get cap() {
        return this._cap;
    }

    validate() {
        if (this.validator.validate(DELIVERY_TIMESLOT_SCHEMA, this)) {
            return true;
        }
        return false;
    }

    getValidationErrors() {
        return this.validator.getValidationErrors();
    }
}