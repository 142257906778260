import Product from '../Product';
import {
    isArray,
    isObject,
    isNullOrUndefined
} from '../../../helpers/utils';
import { Validator } from '../../core';
import {
    ProductExpiration,
    ProductMeasurement,
    ProductNutritionValue
} from './Common';

const BEVERAGE_META_SCHEMA = {
    beverage_expiration: { type: 'classInstance', rules: { required: true } },
    beverage_allergens: { type: 'array', rules: { itemsType: 'string' } },
    beverage_packaging: { type: 'string', rules: { required: true } },
    beverage_ingredients: { type: 'array', rules: { itemsType: 'string' } },
    beverage_measurement: { type: 'classInstance' },
    beverage_nutrition_values: { type: 'array', rules: { itemsType: 'classInstance' } },
    beverage_gross_weight_measurement: { type: 'classInstance' }
};

/**
 * Describes beverage product meta properties.
 * Used with 'beverage' product type.
 * 
 * @class
 */
export default class Beverage extends Product {

    constructor() {
        super();
        // JSON properties
        this._meta = null;
    }

    /* SETTER METHODS */

    set meta(meta) {
        if (isNullOrUndefined(meta) || !isObject(meta)) {
            return;
        }
        this._meta = new BeverageMeta();
        this._meta.loadFromJSON(meta);
    }

    /* GETTER METHODS */

    get meta() {
        return this._meta;
    }
}

class BeverageMeta {
    constructor() {
        this.validator = new Validator();
        // JSON properties
        this._beverageAllergens = null;
        this._beverageExpiration = null;
        this._beverageIngredients = null;
        this._beverageMeasurement = null;
        this._beverageNutritionValues = null;
        this._beveragePackaging = null;
        this._beverageGrossWeightMeasurement = null;
    }

    loadFromJSON(data) {
        if (!isObject(data)) {
            return;
        }
        // Use json property key as setter method for class property
        for (let i in data) {
            this[i] = data[i];
        }
    }

    /* SETTER METHODS */

    set beverage_allergens(beverageAllergens) {
        this._beverageAllergens = beverageAllergens;
    }

    set beverage_expiration(beverageExpiration) {
        if (isNullOrUndefined(beverageExpiration) || !isObject(beverageExpiration)) {
            return;
        }
        this._beverageExpiration = new ProductExpiration();
        this._beverageExpiration.loadFromJSON(beverageExpiration);
    }

    set beverage_ingredients(beverageIngredients) {
        this._beverageIngredients = beverageIngredients;
    }

    set beverage_measurement(beverageMeasurement) {
        if (isNullOrUndefined(beverageMeasurement) || !isObject(beverageMeasurement)) {
            return;
        }
        this._beverageMeasurement = new ProductMeasurement();
        this._beverageMeasurement.loadFromJSON(beverageMeasurement);
    }

    set beverage_nutrition_values(beverageNutritionValues) {
        if (isNullOrUndefined(beverageNutritionValues) || !isArray(beverageNutritionValues)) {
            return;
        }
        this._beverageNutritionValues = [];
        for (let i in beverageNutritionValues) {
            let bnv = new ProductNutritionValue();
            bnv.loadFromJSON(beverageNutritionValues[i]);
            this._beverageNutritionValues.push(bnv);
        }
    }

    set beverage_packaging(beveragePackaging) {
        this._beveragePackaging = beveragePackaging;
    }

    set beverage_gross_weight_measurement(beverageGrossWeightMeasurement) {
        if (isNullOrUndefined(beverageGrossWeightMeasurement) || !isObject(beverageGrossWeightMeasurement)) {
            return;
        }
        this._beverageGrossWeightMeasurement = new ProductMeasurement();
        this._beverageGrossWeightMeasurement.loadFromJSON(beverageGrossWeightMeasurement);
    }

    /* GETTER METHODS */

    get beverage_allergens() {
        return this._beverageAllergens;
    }

    get beverage_expiration() {
        return this._beverageExpiration;
    }

    get beverage_ingredients() {
        return this._beverageIngredients;
    }

    get beverage_measurement() {
        return this._beverageMeasurement;
    }

    get beverage_nutrition_values() {
        return this._beverageNutritionValues;
    }

    get beverage_packaging() {
        return this._beveragePackaging;
    }

    get beverage_gross_weight_measurement() {
        return this._beverageGrossWeightMeasurement;
    }

    validate() {
        return this.validator.validate(BEVERAGE_META_SCHEMA, this);
    }

    getValidationErrors() {
        return this.validator.getValidationErrors();
    }
}