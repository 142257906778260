import { APICall } from '../core';
import { isString } from '../../helpers/utils';
import Page from './Page';

export default class PagesService {

    constructor(bc) {
        this.bc = bc;
    }

    getAll() {
        const request = new APICall(
            this.bc.apiKey,
            null,
            `${this.bc.baseUrl}/v1/pages`,
            'GET',
        );
        return request.send();
    }

    getPageById(id) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Page id was not specified properly.'
            });
        }
        const request = new APICall(
            this.bc.apiKey,
            null,
            `${this.bc.baseUrl}/v1/pages/${id}`,
            'GET',
        );
        return request.send();
    }

    insertPage(p) {
        if (!(p instanceof Page)) {
            return Promise.reject({
                status: 0,
                message: 'Page data argument must be an instance of the Page class.'
            });
        }
        if (!p.validate()) {
            return Promise.reject({
                status: 0,
                message: 'Invalid page data.',
                errors: p.getValidationErrors()
            });
        }
        let payload = p.loadToJSON();
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/pages`,
            'POST',
            payload
        );
        return request.send();
    }

    updatePage(id, p) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Page id was not specified properly.'
            });
        }
        if (!(p instanceof Page)) {
            return Promise.reject({
                status: 0,
                message: 'Page data argument must be an instance of the Page class.'
            });
        }
        if (!p.validate()) {
            return Promise.reject({
                status: 0,
                message: 'Invalid page data.',
                errors: p.getValidationErrors()
            });
        }
        let payload = p.loadToJSON();
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/pages/${id}`,
            'PUT',
            payload
        );
        return request.send();
    }

    deletePage(id) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Page id was not specified properly.'
            });
        }
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/pages/${id}`,
            'DELETE'
        );
        return request.send();
    }
}