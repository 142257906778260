import {
    isObject,
    extractJsonProps,
    objectKeysToSnakeCase,
    isNullOrUndefined
} from '../../helpers/utils';
import Location from './Location';
import Profile from './Profile';
import { Validator } from '../core';

const USER_SCHEMA = {
    id: { type: 'string' },
    first_name: { type: 'string', rules: { required: true, minLength: 2 } },
    last_name: { type: 'string', rules: { required: true, minLength: 2 } },
    picture: { type: 'string' },
    email: { type: 'string', rules: { required: true } },
    phone: { type: 'string' },
    gender: { type: 'string' },
    role: { type: 'string' },
    location: { type: 'classInstance' },
    profile: { type: 'classInstance' }
};

export default class User {
    constructor() {
        this.validator = new Validator();
        // JSON properties
        this._id = null;
        this._firstName = null;
        this._lastName = null;
        this._picture = null;
        this._email = null;
        this._phone = null;
        this._gender = null;
        this._role = null;
        this._location = null;
        this._profile = null;
    }

    loadToJSON() {
        // get all properties, ignore methods
        var data = JSON.parse(JSON.stringify(this));
        // extract properties that are part of the json
        extractJsonProps(data);
        return objectKeysToSnakeCase(data);
    }

    loadFromJSON(data) {
        if (!isObject(data)) {
            throw new Error('User data was not specified properly.');
        }
        // Use json property key as setter method for class property
        for (let i in data) {
            this[i] = data[i];
        }
    }

    /* SETTER METHODS */

    set id(id) {
        this._id = id;
    }

    set first_name(firstName) {
        this._firstName = firstName;
    }

    set last_name(lastName) {
        this._lastName = lastName;
    }

    set picture(picture) {
        this._picture = picture;
    }

    set email(email) {
        this._email = email;
    }

    set phone(phone) {
        this._phone = phone;
    }

    set gender(gender) {
        this._gender = gender;
    }

    set role(role) {
        this._role = role;
    }

    set location(location) {
        if (isNullOrUndefined(location)) {
            return;
        }
        if (!isObject(location)) {
            return;
        }
        this._location = new Location();
        this._location.loadFromJSON(location);
    }

    set profile(profile) {
        if (isNullOrUndefined(profile)) {
            return;
        }
        if (!isObject(profile)) {
            return;
        }
        this._profile = new Profile();
        this._profile.loadFromJSON(profile);
    }

    // GETTER METHODS

    get id() {
        return this._id;
    }

    get first_name() {
        return this._firstName;
    }

    get last_name() {
        return this._lastName;
    }

    get picture() {
        return this._picture;
    }

    get email() {
        return this._email;
    }

    get phone() {
        return this._phone;
    }

    get gender() {
        return this._gender;
    }

    get role() {
        return this._role;
    }

    get location() {
        return this._location;
    }

    get profile() {
        return this._profile;
    }

    validate() {
        if (this.validator.validate(USER_SCHEMA, this)) {
            return true;
        }
        return false;
    }

    getValidationErrors() {
        return this.validator.getValidationErrors();
    }
}