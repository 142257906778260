import Product from '../Product';
import {
    isObject,
    isNullOrUndefined
} from '../../../helpers/utils';
import { Validator } from '../../core';
import {
    ProductExpiration,
    ProductMeasurement
} from './Common';

const CLEANING_META_SCHEMA = {
    cleaning_expiration: { type: 'classInstance', rules: { required: true } },
    cleaning_packaging: { type: 'string', rules: { required: true } },
    cleaning_ingredients: { type: 'array', rules: { itemsType: 'string' } },
    cleaning_measurement: { type: 'classInstance' },
    cleaning_gross_weight_measurement: { type: 'classInstance' }
};

/**
 * Describes cleaning product meta properties.
 * Used with 'cleaning' product type.
 * 
 * @class
 */
export default class Cleaning extends Product {

    constructor() {
        super();
        // JSON properties
        this._meta = null;
    }

    /* SETTER METHODS */

    set meta(meta) {
        if (isNullOrUndefined(meta) || !isObject(meta)) {
            return;
        }
        this._meta = new CleaningMeta();
        this._meta.loadFromJSON(meta);
    }

    /* GETTER METHODS */

    get meta() {
        return this._meta;
    }
}

class CleaningMeta {
    constructor() {
        this.validator = new Validator();
        // JSON properties
        this._cleaningExpiration = null;
        this._cleaningIngredients = null;
        this._cleaningMeasurement = null;
        this._cleaningPackaging = null;
        this._cleaningGrossWeightMeasurement = null;
    }

    loadFromJSON(data) {
        if (!isObject(data)) {
            return;
        }
        // Use json property key as setter method for class property
        for (let i in data) {
            this[i] = data[i];
        }
    }

    /* SETTER METHODS */

    set cleaning_expiration(cleaningExpiration) {
        if (isNullOrUndefined(cleaningExpiration) || !isObject(cleaningExpiration)) {
            return;
        }
        this._cleaningExpiration = new ProductExpiration();
        this._cleaningExpiration.loadFromJSON(cleaningExpiration);
    }

    set cleaning_ingredients(cleaningIngredients) {
        this._cleaningIngredients = cleaningIngredients;
    }

    set cleaning_measurement(cleaningMeasurement) {
        if (isNullOrUndefined(cleaningMeasurement) || !isObject(cleaningMeasurement)) {
            return;
        }
        this._cleaningMeasurement = new ProductMeasurement();
        this._cleaningMeasurement.loadFromJSON(cleaningMeasurement);
    }

    set cleaning_packaging(cleaningPackaging) {
        this._cleaningPackaging = cleaningPackaging;
    }

    set cleaning_gross_weight_measurement(cleaningGrossWeightMeasurement) {
        if (isNullOrUndefined(cleaningGrossWeightMeasurement) || !isObject(cleaningGrossWeightMeasurement)) {
            return;
        }
        this._cleaningGrossWeightMeasurement = new ProductMeasurement();
        this._cleaningGrossWeightMeasurement.loadFromJSON(cleaningGrossWeightMeasurement);
    }

    /* GETTER METHODS */

    get cleaning_expiration() {
        return this._cleaningExpiration;
    }

    get cleaning_ingredients() {
        return this._cleaningIngredients;
    }

    get cleaning_measurement() {
        return this._cleaningMeasurement;
    }

    get cleaning_packaging() {
        return this._cleaningPackaging;
    }

    get cleaning_gross_weight_measurement() {
        return this._cleaningGrossWeightMeasurement;
    }

    validate() {
        return this.validator.validate(CLEANING_META_SCHEMA, this);
    }

    getValidationErrors() {
        return this.validator.getValidationErrors();
    }
}