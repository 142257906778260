import {
    isObject,
    extractJsonProps,
    objectKeysToSnakeCase
} from '../../helpers/utils';
import { Validator } from '../core';

const CART_ITEM_SCHEMA = {
    id: { type: 'string' },
    cart_id: { type: 'string', rules: { required: true } },
    product_id: { type: 'string', rules: { required: true } },
    item_count: { type: 'integer', rules: { required: true, exclusiveMinimum: 0 } }
};

export default class CartItem {
    constructor() {
        this.validator = new Validator();
        // JSON properties
        this._id = null;
        this._cartId = null;
        this._productId = null;
        this._itemCount = null;
    }

    loadToJSON() {
        // get all properties, ignore methods
        var data = JSON.parse(JSON.stringify(this));
        // extract properties that are part of the json
        extractJsonProps(data);
        return objectKeysToSnakeCase(data);
    }

    loadFromJSON(data) {
        if (!isObject(data)) {
            throw new Error('Cart item data was not specified properly');
        }
        // Use json properties keys as setter methods for class properties
        for (let i in data) {
            this[i] = data[i];
        }
    }

    /* SETTER METHODS */

    set id(id) {
        this._id = id;
    }

    set cart_id(cartId) {
        this._cartId = cartId;
    }

    set product_id(productId) {
        this._productId = productId;
    }

    set item_count(itemCount) {
        this._itemCount = itemCount;
    }

    /* GETTER METHODS */

    get id() {
        return this._id;
    }

    get cart_id() {
        return this._cartId;
    }

    get product_id() {
        return this._productId;
    }

    get item_count() {
        return this._itemCount;
    }

    validate() {
        if (this.validator.validate(CART_ITEM_SCHEMA, this)) {
            return true;
        }
        return false;
    }

    getValidationErrors() {
        return this.validator.getValidationErrors();
    }
}